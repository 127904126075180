import {THEME_CONFIG} from "../../configs/AppConfig";
import {createSlice} from "@reduxjs/toolkit";
import {notification} from "antd";

const initTheme = {...THEME_CONFIG};

const themeSlice = createSlice({
    name: "theme",
    initialState: initTheme,
    reducers: {
        toggleCollapsedNav: (state, action) => {
            state.navCollapsed = action.payload.navCollapsed
        },
        sideNavStyleChanged: (state, action) => {
            state.sideNavTheme = action.payload.sideNavTheme;
        },
        changeLocale: (state, action) => {
            state.locale = action.payload.locale;
        },
        navTypeChanged: (state, action) => {
            state.navType = action.payload.navType;
        },
        topNavColorChanged: (state, action) => {
            state.topNavColor = action.payload.topNavColor;
        },
        headerNavColorChanged: (state, action) => {
            state.headerNavColor = action.payload.headerNavColor;
        },
        toggleMobileName: (state, action) => {
            state.mobileNav = action.payload.mobileNav;
        },
        showNetworkErrorMessage: (state, action) => {
            let notificationParam = {
            	message: action.payload.title || 'Request Failure',
                description: action.payload.message
            }
            notification.error(notificationParam)
        },
        showPageLoader: (state, action) => {
            state.pageLoader.show = true
            state.pageLoader.message = action.payload
        },
        hidePageLoader: (state, action) => {
            state.pageLoader.show = false
        }
    }
});

export const {toggleCollapsedNav, sideNavStyleChanged, changeLocale, navTypeChanged, topNavColorChanged,
    headerNavColorChanged, toggleMobileName, showNetworkErrorMessage, showPageLoader, hidePageLoader} = themeSlice.actions;
export const reducer = themeSlice.reducer;