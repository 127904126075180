import React from "react";
import PropTypes from "prop-types";
import {hidePageLoader, showNetworkErrorMessage, showPageLoader} from "../../../../redux/slices/theme";
import {connect} from "react-redux";
import {Button, Col, Row, Switch, Typography} from "antd";
import LegislationReferenceList from "../details/reference-list";
import LegislationReferenceDialog from "../details/reference_modal";
import {ContentType} from "../../../../configs/ContentTypes";
const { Title } = Typography;

class LegislationSubLegislationDetails extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            displayReferences: false
        }
    }

    requestContentEdit() {
        if(this.props.didRequestEdit){
            this.props.didRequestEdit(this.props.content);
        }
    }

    onDismissReferenceModal() {
        this.setState({
            showReferenceForm: false,
            referencedItem: null,
            reference: null
        })
    }

    didCompleteReferenceSave(value) {
        if(this.props.onSaveReferenceRequested){
            this.props.onSaveReferenceRequested(this.props.content, value, this.props.contentType.value, () => {
                this.setState({
                    showReferenceForm: false,
                    referencedItem: null,
                    reference: null
                });
            })
        }
    }

    onAddReference() {
        this.setState({
            showReferenceForm: true,
            referencedItem: this.props.content
        })
    }

    onReferenceEditRequested(item) {
        this.setState({
            showReferenceForm: true,
            referencedItem: this.props.content,
            reference: item
        })
    }

    onReferenceDeleteRequested(item){
        if(this.props.onDeleteReferenceRequested) {
            this.props.onDeleteReferenceRequested(this.props.content, item, this.props.contentType.value, () => {
                this.setState({
                    showReferenceForm: false,
                    referencedItem: null,
                    reference: null
                });
            });
        }
    }

    getReferenceItems() {
        if(this.state.displayReferences) {
            let references = <div className="no-reference">No Reference</div>
            const {content} = this.props
            if(content.references && content.references.length > 0){
                references = <LegislationReferenceList references={content.references}
                                                       didRequestReferenceDelete={item => this.onReferenceDeleteRequested(item)}
                                                       didRequestEdit={item => this.onReferenceEditRequested(item)}/>
            }
            return <div className="references-holder">
                <h4>References</h4>
                <hr/>
                <div>{references}</div>
                <div className="text-right"><Button className="add-reference" onClick={e => this.onAddReference()}>Add Reference</Button></div>
            </div>
        }
    }
    render() {
        const {content, contentType} = this.props;
        const ref = this.getReferenceItems();

        let referenceDialog = "";
        if(this.state.showReferenceForm === true){
            let referenceItem = {};
            if(this.state.reference){
                referenceItem = this.state.reference;
            }
            else{
                referenceItem.identity = "-1";
            }
            referenceDialog = <LegislationReferenceDialog referencingItem={referenceItem} contentType={contentType}
                                                    didCompleteSave={e => this.didCompleteReferenceSave(e)}
                                                    didRequestDismissal={e => this.onDismissReferenceModal()} title={`${contentType.label} Reference`}/>
        }

        return <div className="sub-schedule-details">
            <Row>
                <Col span={18}>
                    <Title level={3}>{content.title}</Title>
                </Col>
                <Col span={6} className="text-right">
                    <span>Show References <Switch onChange={e => this.setState({displayReferences: e})}/></span>
                    <Button type={"link"} onClick={e => this.requestContentEdit()}>Edit</Button>
                </Col>
            </Row>
            <div className="details-content" style={{marginTop: "20px"}}>
                {ref}
                <div dangerouslySetInnerHTML={{__html: content.content_html}}/>
            </div>
            {referenceDialog}
        </div>
    }
}

LegislationSubLegislationDetails.propTypes = {
    didRequestEdit: PropTypes.func,
    content: PropTypes.any,
    contentType: PropTypes.any,
    onSaveReferenceRequested: PropTypes.func,
    onDeleteReferenceRequested: PropTypes.func
}

const mapStateToProps = ({ contentManagement }) => {
    const {contentCategory} = contentManagement;
    const {contentTypes} = contentCategory;
    return {contentManagement, contentTypes};
}

const mapDispatchToProps = {
    showNetworkErrorMessage, showPageLoader, hidePageLoader
}

export default connect(mapStateToProps, mapDispatchToProps)(LegislationSubLegislationDetails);