import axios from 'axios'
import { AUTH_API_BASE_URL, CONTENT_API_BASE_URL } from 'configs/AppConfig'
import history from '../history'
import utils from "utils";
import { notification } from 'antd';
import {AUTH_TOKEN, PATHS} from "../constants/services/auth";

export const authService = axios.create({
  baseURL: AUTH_API_BASE_URL,
  timeout: 60000
})

export const contentService = axios.create({
	baseURL: CONTENT_API_BASE_URL,
	timeout: 60000
})

// Config
const ENTRY_ROUTE = '/auth/login'
const TOKEN_PAYLOAD_KEY = 'authorization'
const HEADER_REALM = "tax247-auth-realm";
const PUBLIC_REQUEST_KEY = 'public-request'

const requestInterceptorSuccess = config => {
	const jwtToken = utils.storage.getData(AUTH_TOKEN);
	// axios.post()
	if (jwtToken) {
		config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`
	}
	config.headers[HEADER_REALM] = 'BACKEND';

	if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
		history.push(ENTRY_ROUTE)
		window.location.reload();
	}

	return config
}

const requestInterceptorError = error => {
	notification.error({
		message: 'Error'
	})
	return Promise.reject(error)
}

const responseInterceptorSuccess = response => {
	const data = response.data;
	return data;
}

const responseInterceptorError = error => {
	// let notificationParam = {
	// 	message: ''
	// }

	const jsonError = error.response.data;
	let errorMessage = "Unknown error has occurred"
	if(jsonError) {
		errorMessage = jsonError.message;
		if(errorMessage == null || errorMessage === '') {
			const messageList = jsonError.errors?.map(item => item.message);
			if(messageList.length > 0) {
				errorMessage = messageList[0];
			}
		}
		// notificationParam.message = errorMessage
		// notification.error(notificationParam)
	}


	const statusCode = error.response.status;

	console.log(statusCode);
	if(statusCode === 404){
		errorMessage = errorMessage || "Requested resource not found"
	}
	else if(statusCode === 403 || statusCode === 401) {
		errorMessage = errorMessage || "Restricted access."
		if(error.config.url !== PATHS.login) {
			utils.storage.remove(AUTH_TOKEN);
			// history.push(ENTRY_ROUTE);
			// window.location.reload();
		}
	}
	const errorData = {
		message: errorMessage,
		errorResponse: jsonError
	}
	return Promise.reject(errorData);

	// Remove token and redirect
	// if (error.response.status === 400 || error.response.status === 403) {
	// 	notificationParam.message = 'Authentication Fail'
	// 	notificationParam.description = 'Please login again'
	// 	localStorage.removeItem(AUTH_TOKEN)
	// 	history.push(ENTRY_ROUTE)
	// 	window.location.reload();
	// }
	//
	// if (error.response.status === 404) {
	// 	notificationParam.message = 'Not Found'
	// }
	//
	// if (error.response.status === 500) {
	// 	notificationParam.message = 'Internal Server Error'
	// }
	//
	// if (error.response.status === 508) {
	// 	notificationParam.message = 'Time Out'
	// }
	//
	// notification.error(notificationParam)
}

// API Request interceptor
authService.interceptors.request.use(requestInterceptorSuccess, requestInterceptorError)
contentService.interceptors.request.use(requestInterceptorSuccess, requestInterceptorError)

// API response interceptor
authService.interceptors.response.use( responseInterceptorSuccess, responseInterceptorError);
contentService.interceptors.response.use( responseInterceptorSuccess, responseInterceptorError);