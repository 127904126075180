import React from "react";
import {Editor} from "@tinymce/tinymce-react";

class RichEditorInput extends React.Component{
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(content, editor) {
        const {onChange} = this.props;
        if (onChange) {
            onChange(content);
        }
    }
    render() {
        const defaultFonts = "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats";
        const {value} = this.props;
        return <Editor
            apiKey="b209679mgy6uy43hxkdqpu4zxpnie4lxkc6pe70pjhqowpf2"
            onSaveContent={e => this.handleChange(e)}
            value={value}
            init={{
                height: 500,
                menubar: 'view | table',
                font_formats: defaultFonts,
                plugins: [
                    'advlist autolink lists link image',
                    'charmap print preview anchor help',
                    'searchreplace visualblocks code',
                    'insertdatetime media table paste wordcount', 'table', 'fullscreen', 'link'
                ],
                fullscreen_native: true,
                // color_cols: 5,
                toolbar:
                    'undo redo | formatselect | fontselect | bold italic link | forecolor alignleft aligncenter alignright | bullist numlist outdent indent | fullscreen'
            }}
            onEditorChange={this.handleChange}
        />
    }
}

export default RichEditorInput;