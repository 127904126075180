import React, {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Progress, List} from "antd";

const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isDragActive) {
        return '#2196f3';
    }
    return '#eeeeee';
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;

function StyledDropzone(props) {
    const theProps = props.dropzoneProps || {};
    const {didSelectFile, uploadProgress} = props;
    const onDrop = useCallback(acceptedFiles => {
        didSelectFile(acceptedFiles);
    }, []);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        acceptedFiles
    } = useDropzone({...theProps, onDrop});
    // const files = acceptedFiles.map(file => <li key={file.path}>{file.path}</li>);
    return (
        <div className="container">
            <Container {...getRootProps({isDragActive, isDragAccept, isDragReject, className: 'dropzone'})}>
                <input {...getInputProps()} />
                <p>{props.message || "Drag 'n' drop file(s) here, or click to select file(s)"}</p>
            </Container>
            <div className="styled-dropzone-files">
                <List
                    size="small"
                    header={<strong>Selected File(s)</strong>}
                    itemLayout="horizontal"
                    bordered
                    dataSource={acceptedFiles}
                    renderItem={item => (
                        <List.Item>
                            {item.path}
                        </List.Item>
                    )}
                />
            </div>
            <div className="styled-dropzone-progress">
                <Progress percent={uploadProgress} status="active" showInfo={false} />
            </div>
        </div>
    );
}
StyledDropzone.propTypes = {
    didSelectFile: PropTypes.func,
    dropzoneProps: PropTypes.any,
    message: PropTypes.string,
    uploadProgress: PropTypes.number
};
export default StyledDropzone;