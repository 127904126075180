import React from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Form, Input} from "antd";
import RichEditorInput from "../../../reuseable/rich_editor";
import {ContentFormEditorConfigurator} from "../configurator/base";
import {ContentType} from "../../../../configs/ContentTypes";

class CircularEditForm extends React.Component{

    ownFields = ['regulatory_agency'];
    onFinish(values){
        const {onSaveCallback} = this.props;
        let valuesToSave = ContentFormEditorConfigurator.moveOwnProperties(this.ownFields, "circular", values);
        if(onSaveCallback){
            valuesToSave['content_type'] = ContentType.circular.value;
            onSaveCallback(valuesToSave);
        }
    }

    getForm() {
        return this.formReference;
    }

    render() {
        const {content} = this.props;
        const children = <div>
            <Form.Item
                name="regulatory_agency"
                label="Regulatory Agency"
                rules={[]}>
                <Input />
            </Form.Item>
            <Form.Item
                name="content_html"
                label="Content"
                rules={[
                ]}>
                <RichEditorInput/>
            </Form.Item>
        </div>
        const val = ContentFormEditorConfigurator.getEditForm(content, this.onFinish.bind(this), children, true, true, ContentType.circular)
        const form = val[0];
        this.formReference = val[1];
        return form;
    }
}

const mapStateToProps = ({ contentManagement }) => {
    return {contentManagement};
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(CircularEditForm);