import DefaultConfigurator from "./base";
import {CheckCircleTwoTone} from "@ant-design/icons";
import React from "react";

export default class SectionConfigurator extends DefaultConfigurator{
    addDefaultToRequestData(data){

    }
    updateColumns(columns){
        const draftMode = {
            title: 'Draft Mode',
            dataIndex: 'isDraftMode',
            key: 'isDraftMode',
            filters: [{text: 'In Draft Mode', value: true}],
            render: (draft, record) => {
                if (draft){
                    return <CheckCircleTwoTone style={{color:'#52c41a', fontSize: '22px'}} />
                }
                else{
                    return <div/>
                }
            },
        }
        columns.push(draftMode);
        return columns;
    }
}