import React from "react";
import {connect} from "react-redux";
import BackendContentService from "../../../../services/content_services";
import {Col, Row, Typography} from "antd";
import {hidePageLoader, showNetworkErrorMessage, showPageLoader} from "../../../../redux/slices/theme";
import {notification} from "antd";
import PropTypes from "prop-types";

const { Title } = Typography;
class ContentEditFormItem extends React.Component{

    formReference
    constructor(props) {
        super(props);
        this.state = {};
    }


    isEditForm(){
        const {content} = this.props;
        return content && content.id > 0;
    }

    getForm() {
        if(this.formReference != null) {
            const current = this.formReference.current;
            if(current != null){
                const formCurrent = current.getForm()
                if(formCurrent != null){
                    return formCurrent.current;
                }
            }
        }
        return null;
    }

    onFormContentSaved(content){

        const {showPageLoader, hidePageLoader, showNetworkErrorMessage, contentTypeData} = this.props
        const label = contentTypeData.label
        showPageLoader(`Saving ${label} Item`);
        BackendContentService.saveContent(content).then((response) => {
            hidePageLoader();
            let notificationParam = {
                message: "Content Updated",
                description: "Your content has been successfully updated"
            };
            notification.success(notificationParam);
            if(this.props.contentUpdateCallback){
                this.props.contentUpdateCallback(content.content_type);
            }
        }).catch(error => {
            hidePageLoader();
            showNetworkErrorMessage(error)
        })
    }

    buildForm(content){
        const {editConfigurator, contentTypeData} = this.props;
        const data = editConfigurator.buildForm(contentTypeData, content, this.isEditForm() === false, this.onFormContentSaved.bind(this));
        this.formReference = data[1];
        return data[0];
    }

    render() {
        const {content, contentTypeData} = this.props;
        const form = this.buildForm(content);
        let title = <Title level={4}>Create {contentTypeData.label}</Title>;
        if(this.isEditForm() === true){
            title = <Title level={4}>Edit <small>{content.title}</small></Title>;
        }

        return <div style={{marginTop: "20px"}}>
            {title}
            <Row>
                <Col span={24}>
                    <div className={"form-wrapper"}>{form}</div>
                </Col>
            </Row>
        </div>;
    }

}

ContentEditFormItem.propTypes = {
    content: PropTypes.any,
    contentTypeData: PropTypes.any,
    editConfigurator: PropTypes.any,
    contentUpdateCallback: PropTypes.func
}

const mapStateToProps = ({ contentManagement }) => {
    const {contentCategory} = contentManagement;
    const {contentTypes} = contentCategory;
    return {contentManagement, contentTypes};
}

const mapDispatchToProps = {
    showPageLoader, hidePageLoader, showNetworkErrorMessage
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(ContentEditFormItem);