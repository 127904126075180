const dev = {
	// AUTH_API_ENDPOINT_URL: 'https://connect.tax247.com.ng/api/auth',
	// CONTENT_API_ENDPOINT_URL: 'https://connect.tax247.com.ng/api/content'
  	AUTH_API_ENDPOINT_URL: 'http://connect.tax247.test/api/auth',
	CONTENT_API_ENDPOINT_URL: 'http://connect.tax247.test/api/content'
};

const prod = {
	AUTH_API_ENDPOINT_URL: 'https://connect.tax247.com.ng/api/auth',
	CONTENT_API_ENDPOINT_URL: 'https://connect.tax247.com.ng/api/content'
};

const test = {
	AUTH_API_ENDPOINT_URL: 'http://connect.tax247.test/api/auth',
	CONTENT_API_ENDPOINT_URL: 'http://connect.tax247.test/api/content'
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const isProductionEnvironment = () => {
	switch (process.env.NODE_ENV) {
		case 'production':
			return true;
		default:
			return false;
	}
}

export const env = getEnv()
