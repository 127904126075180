import React from "react";
import {hidePageLoader, showNetworkErrorMessage, showPageLoader} from "../../../../redux/slices/theme";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import InnerAppLayout from "../../../../layouts/inner-app-layout";
import BackendContentService from "../../../../services/content_services";
import {ContentType} from "../../../../configs/ContentTypes";
import {Col, Menu, Modal, Row, Spin, Switch} from "antd";
import {BulkListMenuKey} from "../bulk_menu_keys";
import {
    CheckCircleTwoTone,
    PlusCircleOutlined,
    RadiusUprightOutlined,
    RollbackOutlined,
    VerticalAlignTopOutlined
} from "@ant-design/icons";
import lodash from "lodash";
import LegislationSubLegislationEdit from "./edit";
import LegislationSubLegislationDetails from "./details";

class LegislationSubLegislation extends React.Component{
    listRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            selectedContentList: [],
            inEditMode: false
        }
        this.didRequestSaveReference = this.didRequestSaveReference.bind(this);
        this.didRequestDeleteReference = this.didRequestDeleteReference.bind(this);
    }

    onModalDismissed() {
        const {onDismissRequested} = this.props;
        if(onDismissRequested){
            onDismissRequested();
        }
    }

    onItemSelected(contentList, contentType) {
        this.setState({
            inEditMode: false,
            selectedContentList: contentList
        })
    }

    onHandleMenuSelection(key, items) {
        if(key === BulkListMenuKey.add){
            this.setState({
                inEditMode: true,
                selectedContentList: []
            })
        }
        else{
            if(items && items.length > 0){
                /**
                 * @type {LegislationDetailsConfigurator}
                 */
                const detailsConfig = this.props.detailsConfigurator;
                detailsConfig.onDetailsMenuSelected(key, items, this.props.contentTypeData, this, () => {
                    this.listRef.current.reloadContent();
                })
            }
        }
    }

    onRequestedContentEdit(content) {
        this.setState({
            inEditMode: true,
            selectedContentList: [content]
        })
    }

    onContentUpdated(e){
        this.setState({
            inEditMode: false,
            selectedContentList: []
        })
        this.listRef.current.reloadContent()
    }

    getMainContent() {
        let mainContent = <div/>
        const {selectedContentList, inEditMode} = this.state;
        if(inEditMode) {
            if(selectedContentList.length === 0) {
                // we are adding a new item
                const content = {
                    legislation: this.props.legislationContent.id,
                    isDraftMode: this.props.legislationContent.isDraftMode,
                    published: this.props.legislationContent.published
                };
                mainContent = <LegislationSubLegislationEdit content={content} contentTypeData={this.props.contentTypeData}
                                                             contentUpdateCallback={e => this.onContentUpdated(e)}/>
            }
            else if(selectedContentList.length === 1){
                mainContent = <LegislationSubLegislationEdit content={selectedContentList[0]}
                                                             contentTypeData={this.props.contentTypeData}
                                                             contentUpdateCallback={e => this.onContentUpdated(e)}/>
            }
        }
        else{
            if(selectedContentList.length === 1){
                mainContent = <LegislationSubLegislationDetails content={selectedContentList[0]}
                                                                didRequestEdit={c => this.onRequestedContentEdit(c)}
                                                                onSaveReferenceRequested={this.didRequestSaveReference}
                                                                onDeleteReferenceRequested={this.didRequestDeleteReference}
                                                                contentType={this.props.contentTypeData}/>
            }
        }
        return mainContent;
    }

    didRequestDeleteReference(reference, value, contentType, callback) {
        this.props.detailsConfigurator.didRequestDeleteReference(reference, value, contentType, callback, ()=> {
            this.onContentUpdated();
            callback();
        })
    }

    didRequestSaveReference(reference, value, contentType, callback) {
        this.props.detailsConfigurator.didRequestSaveReference(reference, value,contentType, ()=> {
            this.onContentUpdated();
            callback();
        });
    }

    render() {
        const {contentTypeData, legislationContent} = this.props;
        // const {selectedContentList} = this.state
        let title = `${contentTypeData.label} - ${legislationContent.title}`;
        let sideContent = <LegislationSubLegislationSideList ref={this.listRef} contentType={contentTypeData}
                                                             handleMenuSelection={(k, s) => this.onHandleMenuSelection(k, s)}
                                                             legislation={legislationContent} onItemSelected={(s, t) => this.onItemSelected(s, t)}/>
        let mainContent = this.getMainContent();
        return <Modal
            className="subleg-schedule-modal"
            visible={true}
            width="90%"
            title={title} closable={true}
            footer={null}
            centered={true} onCancel={e => this.onModalDismissed()}>
            <InnerAppLayout sideContent={sideContent} sideContentWidth={400} sideContentGutter={true} border mainContent={mainContent}/>
        </Modal>
    }
}

LegislationSubLegislation.propTypes = {
    contentTypeData: PropTypes.any,
    onDismissRequested: PropTypes.func,
    legislationContent: PropTypes.any,
    detailsConfigurator: PropTypes.any
}

const mapStateToProps = ({ contentManagement }) => {
    const {contentCategory} = contentManagement;
    const {contentTypes} = contentCategory;
    return {contentManagement, contentTypes};
}

const mapDispatchToProps = {
    showNetworkErrorMessage, showPageLoader, hidePageLoader
}

export default connect(mapStateToProps, mapDispatchToProps)(LegislationSubLegislation);

class LegislationSubLegislationSideList extends React.Component {
    allContent = [];
    reloadingContent = false
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            contentList: [],
            allContentList: [],
            allowMultipleSelect: false,
            selectedContentList: []
        }
        this.loadContent()
    }

    reloadContent(){
        this.reloadingContent = true;
        this.allContent = []
        this.loadContent();
    }

    loadContent(page) {
        const {legislation, contentType} = this.props;
        let observe;
        if(contentType.value === ContentType.schedule.value){
            observe = BackendContentService.getScheduleForLegislation(legislation.id, page)
        }
        else{
            observe = BackendContentService.getSubLegislationForLegislation(legislation.id, page)
        }
        this.setState({
            loading: true
        })
        observe.then((response) => {
            const data = response.result.data;
            const nextPage = response.next_page;
            if(data && data.length > 0){
                data.forEach(item => {
                    this.allContent.push(item);
                })
            }
            if(nextPage){
                this.loadContent(nextPage);
            }
            else{
                this.setState({
                    loading: false,
                    contentList: this.allContent.slice(),
                    allContentList: this.allContent.slice(),
                });
                this.afterReload();
            }

        }).catch(error => {
            showNetworkErrorMessage(error)
            this.setState({
                loading: false,
            });
        });
    }

    afterReload() {
        if(this.reloadingContent) {
            this.reloadingContent = false;
            this.notifySelectionChanged([]);
        }
    }

    notifySelectionChanged(selections){
        this.setState({
            selectedContentList: selections
        })
        if(this.props.onItemSelected){
            this.props.onItemSelected(selections, this.props.contentType);
        }
    }

    contentIsActive(content){
        return lodash.find(this.state.selectedContentList, (item) => item.id === content.id);
    }

    selectItem(selected) {
        let copy = this.state.selectedContentList || [];
        if(this.state.allowMultipleSelect){
            const removed = lodash.remove(copy, (item)=> selected.id === item.id);
            if(removed.length > 0){
                // the current item was previously selected, we just unselected it
            }
            else{
                copy.push(selected);
            }
        }
        else{
            copy = [selected];
        }
        this.notifySelectionChanged(copy)
    }

    findContentWithId(id){
        return lodash.find(this.state.contentList, (item) => item.id === id);
    }

    getMenu() {
        const menu = [];
        const addMenu = <Menu.Item key={BulkListMenuKey.add} icon={<PlusCircleOutlined />}>
            Add New
        </Menu.Item>
        menu.push(addMenu);
        const {selectedContentList} = this.state;
        if(selectedContentList.length > 0){

            let hasDraftItem = lodash.findIndex(selectedContentList, (content) => {
                const item = this.findContentWithId(content.id);
                return item && item.isDraftMode === true;
            }) >= 0;
            let hasUndraftItem = lodash.findIndex(selectedContentList, (content) => {
                const item = this.findContentWithId(content.id);
                return item && item.isDraftMode === false;
            }) >= 0;
            let hasPublishedItem = lodash.findIndex(selectedContentList, (content) => {
                const item = this.findContentWithId(content.id);
                return item && item.published === true;
            }) >= 0;
            let hasUnpublishedItems = lodash.findIndex(selectedContentList, (content) => {
                const item = this.findContentWithId(content.id);
                return item && item.published === false;
            }) >= 0;


            if(hasDraftItem){
                menu.push(
                    <Menu.Item key={BulkListMenuKey.publishDraft} icon={<CheckCircleTwoTone />}>
                        Publish Draft
                    </Menu.Item>
                )
            }
            if(hasUndraftItem){
                menu.push(
                    <Menu.Item key={BulkListMenuKey.makeDraft} icon={<RadiusUprightOutlined />}>
                        Make Draft
                    </Menu.Item>
                )

            }
            if(hasPublishedItem){
                menu.push(
                    <Menu.Item key={BulkListMenuKey.unpublish} icon={<RollbackOutlined />}>
                        Un-publish
                    </Menu.Item>
                )

            }
            if(hasUnpublishedItems){
                menu.push(
                    <Menu.Item key={BulkListMenuKey.publish} icon={<VerticalAlignTopOutlined />}>
                        Publish
                    </Menu.Item>
                )

            }

        }

        return <Menu onClick={e => this.handleMenuClick(e)} selectedKeys={[]} mode="horizontal" className="schedule-menu">
            {menu}
        </Menu>
    }

    getSubItems() {
        return <Row className="filter d-flex align-items-center">
            <Col span={12}>
                <div>Show Only Published</div>
            </Col>
            <Col span={12}>
                <div style={{textAlign: 'right'}}>
                    <Switch onChange={e => this.onPublishedChanged(e)}/>
                </div>
            </Col>
        </Row>
    }

    getSubFooterItems() {
        return <Row className="filter d-flex align-items-center">
            <Col span={12}>
                <div>Allow Multiple Selection</div>
            </Col>
            <Col span={12}>
                <div style={{textAlign: 'right'}}>
                    <Switch onChange={e => this.onMultipleToggledClicked(e)}/>
                </div>
            </Col>
        </Row>
    }

    onMultipleToggledClicked(checked) {
        this.setState({
            allowMultipleSelect: checked
        });
    }

    onPublishedChanged(checked) {
        if (checked){
            this.setState({
                contentList: lodash.filter(this.allContent.slice(), (item) => item.published)
            })
        }
        else{
            this.setState({
                contentList: this.allContent.slice()
            })
        }
    }

    handleMenuClick(e) {
        if (this.props.handleMenuSelection) {
            this.props.handleMenuSelection(e.key, this.state.selectedContentList)
        }
    }

    render() {
        const {contentType} = this.props;
        const {contentList} = this.state;
        let wrapperClass = "";

        const loading = `Loading ${contentType.label}.....`;
        if(this.state.loading){
            wrapperClass += " loading-state";
        }
        let children = contentList.map((item, i) => {
            let itemClasses = "";
            if(item.published === false){
                itemClasses = "not-published";
            }
            if(item.isDraftMode === true){
                itemClasses += " is-draft"
            }
            let selectedItemId = this.contentIsActive(item);
            if(selectedItemId){
                selectedItemId = selectedItemId.id
            }
            return <div
                key={`subleg-sch-item-${item.id}`}
                onClick={() => this.selectItem(item)}
                className={`${itemClasses} subleg-sch-item ${i === (contentList.length - 1)? 'last' : ''} ${item.id === selectedItemId? 'selected' : ''}`}>
                <div className="avatar-wrapper d-flex align-items-center">
                    <div className="ml-2">
                        <div>{item.title}</div>
                    </div>
                </div>
            </div>
        })
        if(children && children.length > 0){
            children = <div className="subleg-sch-menu-list">
                {children}
            </div>
        }
        else{
            children = <div className="empty">No item found</div>
        }
        return <div style={{width: "100%"}}>
            <div className="actions">
                {this.getMenu()}
                {this.getSubItems()}
            </div>
            <div className={`list-wrapper ${wrapperClass}`}>
                <Spin tip={loading} spinning={this.state.loading}>
                    {children}
                </Spin>
            </div>
            <div className="actions">
                {this.getSubFooterItems()}
            </div>
        </div>
    }
}