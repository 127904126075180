// import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "redux/reducer";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import rootSaga from "../sagas/index";

const sagaMiddleware = createSagaMiddleware();

const middlewares = [
  ...getDefaultMiddleware(),
  sagaMiddleware,
  /*YOUR CUSTOM MIDDLEWARES HERE*/
];

const store = configureStore({
  reducer: rootReducer,
  middleware: middlewares
})

sagaMiddleware.run(rootSaga);

// if (process.env.NODE_ENV === 'development' && module.hot) {
//   module.hot.accept("redux/reducer", () => {
//     const nextRootReducer = require("redux/reducer").default;
//     store.replaceReducer(nextRootReducer);
//   });
// }

// function configureStore(preloadedState) {
//
//   const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//   const store = createStore(reducers,  preloadedState, composeEnhancers(
//     applyMiddleware(...middlewares)
//   ));
//
//   sagaMiddleware.run(rootSaga);
//
//   if (module.hot) {
//     module.hot.accept("../reducers/index", () => {
//       const nextRootReducer = require("../reducers/index");
//       store.replaceReducer(nextRootReducer);
//     });
//   }
//
//   return store;
// }

export default store;

