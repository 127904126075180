export class BulkListMenuKey {
    static add = "add";
    static edit = "edit";
    static publishDraft = "publish-draft";
    static makeDraft = "make-draft";
    static unpublish = "unpublish";
    static publish = "publish";
    static reindex = "reindex"
}

export class LegislationMenuKey {
    static showSubLegislation = "show-sub-legislation"
    static showSchedules = "show-schedules"
}