import DefaultConfigurator from "./base";
import {RadiusUprightOutlined, CheckCircleTwoTone} from "@ant-design/icons";
import React from "react";
import {Menu} from "antd";
import {LegislationDetailsConfigurator} from "./legislation_details";
import {ContentGroupActionRequestType} from "../../../../configs/content_edit_form_item";
import {BulkListMenuKey} from "../bulk_menu_keys";
const { SubMenu } = Menu;

export default class LegislationConfigurator extends DefaultConfigurator{
    addDefaultToRequestData(data){
        if(!data.sortProperty) {
            data.sortProperty = 'title';
            data.sortDirection = "asc";
        }
    }

    createDetailsConfigurator(component){
        return new LegislationDetailsConfigurator(component);
    }

    updateColumns(columns){
        const acronym = {
            title: 'Acronym',
            dataIndex: 'acronym',
            key: 'acronym',
        }
        columns.splice(1, 0, acronym);
        const draftMode = {
            title: 'Draft Mode',
            dataIndex: 'isDraftMode',
            key: 'isDraftMode',
            filters: [{text: 'In Draft Mode', value: true}],
            render: (draft, record) => {
                if (draft){
                    return <CheckCircleTwoTone style={{color:'#52c41a', fontSize: '22px'}} />
                }
                else{
                    return <div/>
                }
            },
        }
        columns.splice(columns.length - 1, 0, draftMode);
        return columns;
    }

    updateListMenu(menuList, state) {
        if (state.selectedRows.length > 0) {
            const submenu = <SubMenu key="draftAction" title="Draft Action">
                <Menu.Item key={BulkListMenuKey.makeDraft}><RadiusUprightOutlined/> Make Draft</Menu.Item>
                <Menu.Item key={BulkListMenuKey.publishDraft}><CheckCircleTwoTone/> Publish Draft</Menu.Item>
            </SubMenu>
            menuList.push({
                key: 'draftAction',
                content: submenu,
                isSubMenu: true
            })
        }
    }

    handleContentListMenuClick(menuKey, selectedItems, contentType, component, callback) {
        const handled = super.handleContentListMenuClick(menuKey, selectedItems, contentType, component, callback);
        if(handled === false) {
            if(menuKey === BulkListMenuKey.makeDraft || menuKey === BulkListMenuKey.publishDraft) {
                let label = "";
                let action = null;
                if(menuKey === BulkListMenuKey.makeDraft) {
                    action = ContentGroupActionRequestType.makeDraft;
                    label = "Drafting";
                }
                else if(menuKey === BulkListMenuKey.publishDraft) {
                    action = ContentGroupActionRequestType.publishDraft;
                    label = "Publishing Draft";
                }
                const requestData = {
                    content_type: contentType.value,
                    content_ids: selectedItems.map(i => i.id),
                    action: action
                };
                // console.log(requestData);
                this.processMenuBulkAction(label, requestData, contentType, component, callback);
                return true;
            }
        }
        return handled
    }

}