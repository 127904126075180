import React from "react";
import PropTypes from "prop-types";
import {hidePageLoader, showNetworkErrorMessage, showPageLoader} from "../../../../redux/slices/theme";
import {connect} from "react-redux";
import ContentEditFormItem from "../edit/editForm";
import {Button} from "antd";


class LegislationSubLegislationEdit extends React.Component{
    editForm = React.createRef();
    onContentUpdated(values) {
        if (this.props.contentUpdateCallback){
            this.props.contentUpdateCallback();
        }
    }
    triggerSubmitOfForm() {
        this.editForm.current.getForm().submit();
    }
    render() {
        const {contentTypeData, content} = this.props;
        /**
         * @type {ContentTypeData}
         */
        const {configurator} = contentTypeData;
        const editConfigurator = configurator.getFormEditor(this);
        const form = <ContentEditFormItem content={content} contentTypeData={contentTypeData}
                                          editConfigurator={editConfigurator} ref={this.editForm}
                                          contentUpdateCallback={e => this.onContentUpdated()}/>
        return <div className="sub-schedule-editor">
            <div className="edit-content" style={{marginTop: "20px"}}>{form}</div>
            <div className="edit-actions text-right">
                <Button type="primary" onClick={e => this.triggerSubmitOfForm()}>
                    Submit
                </Button>
            </div>
        </div>
    }
}

LegislationSubLegislationEdit.propTypes = {
    content: PropTypes.any,
    contentTypeData: PropTypes.any,
    contentUpdateCallback: PropTypes.func,
}

const mapStateToProps = ({ contentManagement }) => {
    const {contentCategory} = contentManagement;
    const {contentTypes} = contentCategory;
    return {contentManagement, contentTypes};
}

const mapDispatchToProps = {
    showNetworkErrorMessage, showPageLoader, hidePageLoader
}

export default connect(mapStateToProps, mapDispatchToProps)(LegislationSubLegislationEdit);