import React from "react";
import {connect} from "react-redux";
import {Modal} from "antd";
import PropTypes from 'prop-types';
import StyledDropzone from "../../../reuseable/dropzone";
import {hidePageLoader, showNetworkErrorMessage, showPageLoader} from "../../../../redux/slices/theme";
import BackendContentService from "../../../../services/content_services";
class FileUploadModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hasPermission: true,
            selectedFile: null,
            uploadProgress: 0
        }
        this.handleCancel = this.handleCancel.bind(this);
        this.handleModalOk = this.handleModalOk.bind(this);
        this.onFileAccepted = this.onFileAccepted.bind(this);
    }

    handleModalOk() {
        if(this.state.selectedFile){
            const {hidePageLoader, showPageLoader, didFinishUpload} = this.props;
            showPageLoader("Uploading file");
            this.setUploadProgress(0);
            BackendContentService.uploadFile(this.props.enforceImage, this.state.selectedFile, (event) => {
                this.setUploadProgress(Math.round((100 * event.loaded) / event.total));
            }).then((response) => {
                hidePageLoader()
                this.setState({
                    selectedFile: null
                })
                const uploadedUrl = response.result;
                if(didFinishUpload){
                    didFinishUpload(uploadedUrl)
                }
                else{
                    showNetworkErrorMessage({title: "Upload Error", message: "We could not upload the specified file"})
                }
            }).catch((error) => {
                hidePageLoader();
                const {showNetworkErrorMessage} = this.props;
                this.setUploadProgress(0);
                showNetworkErrorMessage({title: "Upload Error", message: error.message})
            });
        }
    }

    setUploadProgress(val) {
        this.setState({
            uploadProgress: val
        })
    }

    handleCancel() {
        this.setState({
            uploadProgress: 0
        })
        if(this.props.didCancel){
            this.props.didCancel();
        }
    }

    enableSubmitButton() {
        return this.state.hasPermission && this.state.selectedFile !== null;
    }

    onFileAccepted(file){
        if(file && file.length > 0){
            const selectedFile = file[0];
            this.setState({selectedFile})
        }
    }

    render() {
        return <Modal
            centered
            width={700}
            visible={true}
            wrapClassName="no-content-padding"
            destroyOnClose={true}
            title="Upload File"
            onOk={this.handleModalOk}
            cancelText="Cancel"
            okButtonProps={{
                disabled: this.enableSubmitButton() === false
            }}
            okText="Upload"
            onCancel={this.handleCancel}>
            <StyledDropzone uploadProgress={this.state.uploadProgress} dropzoneProps={{maxFiles: 1}} didSelectFile={this.onFileAccepted}/>
        </Modal>
    }
}

FileUploadModal.propTypes = {
    didCancel: PropTypes.func,
    didFinishUpload: PropTypes.func,
    enforceImage: PropTypes.bool
}

const mapStateToProps = ({ userManagement }) => {
    return {};
};

const mapDispatchToProps = {
    showPageLoader, hidePageLoader, showNetworkErrorMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(FileUploadModal);