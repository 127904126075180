import DefaultConfigurator, {ContentDetailsConfigurator} from "./base";
import {Card, Col, Row} from "antd";
import React from "react";
import { Typography } from 'antd';
import moment from 'moment';
import utils from "../../../../utils";
import dayjs from "dayjs";

const { Text, Title } = Typography;

export default class EventConfigurator extends DefaultConfigurator{
    updateColumns(columns){
        const effective_date = {
            title: 'End Date',
            dataIndex: 'end_date',
            key: 'end_date',
            sorter: (a, b) => utils.string.compareStrings(`${a.end_date}`, `${b.end_date}`),
            render: (date, record) => {
                if (date){
                    return moment(date).format("DD MMM, YYYY hh:mm A")
                }
                else{
                    return <div/>
                }
            }
        }
        //
        columns.splice(1, 0, effective_date);
        const enforcement = {
            title: 'Start Date',
            dataIndex: 'start_date',
            key: 'start_date',
            sorter: (a, b) => utils.string.compareStrings(`${a.start_date}`, `${b.start_date}`),
            render: (date, record) => {
                if (date){
                    return moment(date).format("DD MMM, YYYY hh:mm A")
                }
                else{
                    return <div/>
                }
            }
        }
        columns.splice(1, 0, enforcement);

        return columns;
    }

    createDetailsConfigurator(component){
        return new DetailsConfigurator(component);
    }
}

class DetailsConfigurator extends ContentDetailsConfigurator{
    getEventMeta(content){
        let startDate = "nil";
        let endDate = "nil";
        let venue = content.event_venue || 'nil';
        if(content.start_date){
            startDate = moment(content.start_date).format('DD MMM YYYY hh:mm A')
        }
        if(content.end_date){
            endDate = moment(content.end_date).format('DD MMM YYYY hh:mm A')
        }
        return <Card className={"meta"}>
            <Row>
                <Col span={8} className={""}>
                    <div><strong>Start Date</strong></div>
                    <div>{startDate}</div>
                </Col>
                <Col span={8} className={"text-center"}>
                    <div><strong>Event Venue</strong></div>
                    <div>{venue}</div>
                </Col>
                <Col span={8} className={"text-right"}>
                    <div><strong>End Date</strong></div>
                    <div>{endDate}</div>
                </Col>
            </Row>
        </Card>
    }
    buildDetailsTitle(title, postTitle, categories, content){
        const val = super.buildDetailsTitle(title, postTitle, categories, content);
        const meta = this.getEventMeta(content);
        return <div>
            {val}<br/>{meta}
        </div>;
    }
}