import React from "react";
import { UploadOutlined } from '@ant-design/icons';
import {Button} from "antd";
import FileUploadModal from "../app-views/users/list/file_upload_modal";

export default class ImageFieldWithUploaderInput extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            showBulkUploadModal: false
        }
    }
    handleChange(content, editor) {

    }
    triggerUpload() {
        this.setState({
            showBulkUploadModal: true
        })
    }
    hideModal() {
        this.setState({
            showBulkUploadModal: false
        })
    }
    onUploadedFinished(url){
        const {onChange} = this.props;
        this.setState({
            showBulkUploadModal: false
        })
        if(onChange){
            onChange(url);
        }
    }
    render() {
        const {value} = this.props;
        const {showBulkUploadModal} = this.state
        const url = `url(${value})`;
        return <div className="image-field-holder">
            <div className="image" style={{backgroundImage: url}}>

            </div>
            <Button onClick={e => this.triggerUpload()}> <UploadOutlined /> Upload</Button>
            {showBulkUploadModal && <FileUploadModal didCancel={e => this.hideModal()}
                                                     didFinishUpload={url => this.onUploadedFinished(url)} enforceImage={true}/>}
        </div>;
    }
}