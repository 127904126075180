import React from "react";
import {connect} from "react-redux";
import {ContentFormEditorConfigurator} from "../configurator/base";
import {ContentType} from "../../../../configs/ContentTypes";
import {Form, Input} from "antd";

class SectionEditForm extends React.Component{

    dateFields = [];
    ownFields = ["section_number", "section_label", "section_sort", "legislation"];
    onFinish(values){
        const {onSaveCallback} = this.props;
        if (!values["section_number"]) {
            values["section_number"] = parseInt(values['section_label']);
        }
        let valuesToSave = ContentFormEditorConfigurator.repopulateDateOnValues(this.dateFields, values);
        valuesToSave = ContentFormEditorConfigurator.moveOwnProperties(this.ownFields, "section", valuesToSave);
        if(onSaveCallback){
            valuesToSave['content_type'] = ContentType.section.value;
            onSaveCallback(valuesToSave);
        }
    }

    getForm() {
        return this.formReference;
    }

    render() {
        const {content} = this.props;
        const formContent = ContentFormEditorConfigurator.prepareDateField(this.dateFields, content)
        const children = <div>
            <Form.Item name="section_label"
                       label="Section Label"
                       rules={[
                           {
                               required: true,
                               message: 'Section label is required',
                           },
                       ]}>
                <Input/>
            </Form.Item>
            <Form.Item hidden={true} name="section_sort"/>
            <Form.Item hidden={true} name="section_number"/>
            <Form.Item hidden={true} name="legislation"/>
        </div>
        const val = ContentFormEditorConfigurator.getEditForm(formContent, this.onFinish.bind(this), children, false, true, ContentType.section, false)
        const form = val[0];
        this.formReference = val[1];
        return form;
    }
}

const mapStateToProps = ({ contentManagement }) => {
    return {contentManagement};
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(SectionEditForm);