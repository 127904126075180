import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH, USER_MANAGE_PREFIX_PATH, CONTENT_MANAGE_PREFIX_PATH, SETTINGS_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/dashboards`} component={lazy(() => import(`./dashboards`))} />
        <Route path={`${USER_MANAGE_PREFIX_PATH}`} component={lazy(() => import('./users'))}/>
          <Route path={`${CONTENT_MANAGE_PREFIX_PATH}`} component={lazy(() => import('./content'))}/>
          <Route path={`${SETTINGS_PREFIX_PATH}`} component={lazy(() => import('./settings'))}/>
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboards`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);
