import DefaultConfigurator, {ContentDetailsConfigurator} from "./base";
import {Card, Col, Row} from "antd";
import React from "react";
import { Typography } from 'antd';
import moment from 'moment';
import utils from "../../../../utils";
import dayjs from "dayjs";

const { Text, Title } = Typography;

export default class TaxTreatyConfigurator extends DefaultConfigurator{
    updateColumns(columns){
        const enforcement = {
            title: 'Enforcement Date',
            dataIndex: 'date_of_enforcement',
            key: 'date_of_enforcement',
            sorter: (a, b) => utils.string.compareStrings(`${a.date_of_enforcement}`, `${b.date_of_enforcement}`),
            render: (date, record) => {
                if (date){
                    return moment(date).format("DD MMM, YYYY")
                }
                else{
                    return <div/>
                }
            }
        }
        columns.splice(1, 0, enforcement);
        const effective_date = {
            title: 'Effective Date',
            dataIndex: 'effective_date',
            key: 'effective_date',
            sorter: (a, b) => utils.string.compareStrings(`${a.effective_date}`, `${b.effective_date}`),
            render: (date, record) => {
                if (date){
                    return moment(date).format("DD MMM, YYYY")
                }
                else{
                    return <div/>
                }
            }
        }
        //
        columns.splice(1, 0, effective_date);
        return columns;
    }

    createDetailsConfigurator(component){
        return new TaxTreatyDetailsConfigurator(component);
    }
}

class TaxTreatyDetailsConfigurator extends ContentDetailsConfigurator{
    getTreatyMeta(content){
        let effectDate = "nil";
        let enforceDate = "nil";
        let signDate = 'nil';
        let signPlace = content.place_of_signing || "nil";
        if(content.effective_date){
            effectDate = moment(content.effective_date).format('DD MMM YYYY')
        }
        if(content.date_of_enforcement){
            enforceDate = moment(content.date_of_enforcement).format('DD MMM YYYY')
        }
        if(content.date_of_signing){
            signDate = moment(content.date_of_signing).format('DD MMM YYYY')
        }

        return <Card className={"meta"}>
            <Row>
                <Col span={6} className={""}>
                    <div><strong>Effective Date</strong></div>
                    <div>{effectDate}</div>
                </Col>
                <Col span={6} className={"text-center"}>
                    <div><strong>Enforcement Date</strong></div>
                    <div>{enforceDate}</div>
                </Col>
                <Col span={6} className={"text-center"}>
                    <div><strong>Date of Signing</strong></div>
                    <div>{signDate}</div>
                </Col>
                <Col span={6} className={"text-right"}>
                    <div><strong>Place of Signing</strong></div>
                    <div>{signPlace}</div>
                </Col>
            </Row>
        </Card>
    }
    buildDetailsTitle(title, postTitle, categories, content){
        const val = super.buildDetailsTitle(title, postTitle, categories, content);
        const meta = this.getTreatyMeta(content);
        return <div>
            {val}<br/>{meta}
        </div>;
    }
}