import utils from "../../utils";
import {createSlice} from "@reduxjs/toolkit";
import {allContentTypes, ContentType, ContentTypeData} from "../../configs/ContentTypes";
import Utils from "../../utils";

const initialState = {
    contentCategory: {
        categories: [],
        categoriesType: [],
        contentTypes: [],
    },
    loadingContentCategories: false,
    savingContentCategories: false,
}

allContentTypes().forEach(item => {
    initialState[item.value] = {
        loadingData: false,
        contentList: [],
        paginate: {
            ...utils.datatable.defaultPagination()
        }
    }
});

const updateStateFromDatatablePayload = (state, payload) => {
    const contentType = payload.contentType
    const response = payload.response;
    state[contentType].loadingData = false
    state[contentType].paginate.pageSize = response.paginate.pageSize;
    state[contentType].paginate.current = response.paginate.page;
    state[contentType].paginate.total = response.paginate.total;
    state[contentType].contentList = response.contentList;
}

const slice = createSlice({
    name: 'content',
    initialState: initialState,
    reducers: {
        contentLoaded: (state, action) => {
            updateStateFromDatatablePayload(state, action.payload)
        },
        // net request
        loadContent: (state, action) => {
            const contentType = action.payload.contentType;
            state[contentType].loadingData = true
        },
        prepareContentTypeForReload:(state, action) => {
            const contentType = action.payload;
            state[contentType].contentList = [];
            state[contentType].paginate = {
                ...utils.datatable.defaultPagination()
            }

            state[ContentType.featuredContent.value].contentList = [];
            state[ContentType.featuredContent.value].paginate = {
                ...utils.datatable.defaultPagination()
            }
        },
        loadContentCategories: (state, action) => {
            state.loadingContentCategories = true;
        },
        loadedContentCategories: (state, action) => {
            state.loadingContentCategories = false
            if(action.payload.data) {
                sanitizeCategoryResponse(action.payload.data);
                state.contentCategory = action.payload.data;
            }
        },
        saveContentCategoryType: (state, action) => {
            state.savingContentCategories = true;
        },
        saveContentCategory: (state, action) => {
            state.savingContentCategories = true;
        },
        savedContentCategoryType: (state, action) => {
            state.savingContentCategories = false;
            if(action.payload.data) {
                sanitizeCategoryResponse(action.payload.data);
                state.contentCategory = action.payload.data;
            }
        },
        savedContentCategory: (state, action) => {
            state.savingContentCategories = false;
            if(action.payload.data) {
                sanitizeCategoryResponse(action.payload.data);
                state.contentCategory = action.payload.data;
            }
        },
        disableLoadingData: (state, action) => {
            const contentType = action.payload.contentType
            state[contentType].loadingData = false
        }
    }
})

function sanitizeCategoryResponse(data){
    let contentTypes = data.contentTypes;
    let categoriesType = data.categoriesType;
    let categories = data.categories;
    contentTypes.forEach(type => {
        categoriesType.forEach(catType => {
            if(catType.content_type === type.label){
                type.categoryType = catType
            }
        });
        if(type.categoryType) {
            let contentTypeCategories = [];
            categories.forEach(cat => {
                if(cat.category_type === type.categoryType.type){
                    contentTypeCategories.push(cat);
                }
            });
            type.categories = contentTypeCategories;
        }
        else{
            type.categoryType = null;
            type.categories = []
        }
    });
    Utils.contentCategorization.categories = data;
}

export const {contentLoaded, loadContent, disableLoadingData, loadContentCategories, loadedContentCategories,
    saveContentCategoryType, saveContentCategory, savedContentCategoryType, savedContentCategory, prepareContentTypeForReload} = slice.actions
export const reducer = slice.reducer;