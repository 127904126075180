import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import utils from "utils";
import {
	showAuthMessage,
	authenticated,
	signOutSuccess,
	signUpSuccess,
	signInWithGoogleAuthenticated,
	signInWithFacebookAuthenticated,

	signInUser, signOut, signUp, signInWithFacebook, signInWithGoogle, signInWithFirebaseEmailPassword
} from "../slices/auth";

import FirebaseService from 'services/FirebaseService'
import BackendAuthService from 'services/auth_service'
import {AUTH_TOKEN, USER_PERMISSIONS, USER_PROFILE} from "../../constants/services/auth";


export function* signInWithFBEmail() {
  yield takeEvery(signInWithFirebaseEmailPassword.type, function* ({payload}) {
		const {email, password} = payload;
		try {
			const user = yield call(FirebaseService.signInEmailRequest, email, password);
			if (user.message) {
				yield put(showAuthMessage({message: user.message}));
			} else {
				utils.storage.setData(AUTH_TOKEN, user.user.uid);
				yield put(authenticated({token: user.user.uid}));
			}
		} catch (err) {
			yield put(showAuthMessage({message: err}));
		}
	});
}

export function* signUserNamePassword() {
	yield takeEvery(signInUser.type, function* ({payload}) {
		const {email, password} = payload;
		try {
			const data = {
				primaryIdentifier: email,
				secondaryIdentifier: password,
				loginType: 'BACKEND'
			};
			const authData = yield call(BackendAuthService.login, data);
			const token = authData.accessToken;
			const createdDate = authData.createdDateTime;
			const expireIn = authData.expiresIn * 1000;
			const expiry = createdDate + expireIn;
			utils.storage.setData(AUTH_TOKEN, token, expiry);
			const user = yield call(BackendAuthService.getUserProfile);
			const permissions = user.permissions;
			utils.storage.setData(USER_PROFILE, user, expiry);
			utils.storage.setData(USER_PERMISSIONS, permissions, expiry);
			yield put(authenticated({token, user}))
		} catch (err) {
			console.log(err);
			yield put(showAuthMessage(err));
		}
	});
}

export function* signOutUser() {
  yield takeEvery(signOut.type, function* () {
		try {
			utils.storage.remove(AUTH_TOKEN);
			utils.storage.remove(USER_PROFILE);
			utils.storage.remove(USER_PERMISSIONS);
			yield put(signOutSuccess());
			// const signOutUser = yield call(FirebaseService.signOutRequest);
			// if (signOutUser === undefined) {
			// 	utils.storage.remove(AUTH_TOKEN);
			// 	yield put(signOutSuccess({user: signOutUser}));
			// } else {
			// 	yield put(showAuthMessage({message: signOutUser.message}));
			// }
		} catch (err) {
			yield put(showAuthMessage({message: err}));
		}
	});
}

export function* signUpWithFBEmail() {
  yield takeEvery(signUp.type, function* ({payload}) {
		const {email, password} = payload;
		try {
			const user = yield call(FirebaseService.signUpEmailRequest, email, password);
			if (user.message) {
				yield put(showAuthMessage({message: user.message}));
			} else {
				utils.storage.setData(AUTH_TOKEN, user.user.uid);
				yield put(signUpSuccess({token: user.user.uid}));
			}
		} catch (error) {
			yield put(showAuthMessage({message: error}));
		}
	}
	);
}

export function* signInWithFBGoogle() {
  yield takeEvery(signInWithGoogle.type, function* () {
		try {
			const user = yield call(FirebaseService.signInGoogleRequest);
			if (user.message) {
				yield put(showAuthMessage({message: user.message}));
			} else {
				utils.storage.setData(AUTH_TOKEN, user.user.uid);
				yield put(signInWithGoogleAuthenticated({token: user.user.uid}));
			}
		} catch (error) {
			yield put(showAuthMessage({message: error}));
		}
	});
}

export function* signInUserWithFacebook() {
  yield takeEvery(signInWithFacebook.type, function* () {
		try {
			const user = yield call(FirebaseService.signInFacebookRequest);
			if (user.message) {
				yield put(showAuthMessage({message: user.message}));
			} else {
				utils.storage.setData(AUTH_TOKEN, user.user.uid);
				yield put(signInWithFacebookAuthenticated({token: user.user.uid}));
			}
		} catch (error) {
			yield put(showAuthMessage({message: error}));
		}
	});
}

// function doSignUserNamePassword(payload) {
// 	return JwtAuthService.login(payload).then(resp => {
// 		resp.json()
// 	});
// }

export default function* rootSaga() {
  yield all([
  		fork(signUserNamePassword),
		fork(signInWithFBEmail),
		fork(signOutUser),
		fork(signUpWithFBEmail),
		fork(signInWithFBGoogle),
		fork(signInUserWithFacebook)
  ]);
}
