import React from "react";
import {connect} from "react-redux";
import {Form} from "antd";
import RichEditorInput from "../../../reuseable/rich_editor";
import {ContentFormEditorConfigurator} from "../configurator/base";
import {ContentType} from "../../../../configs/ContentTypes";

class TaxAlertEditForm extends React.Component{

    onFinish(values){
        const {onSaveCallback} = this.props;
        if(onSaveCallback){
            values['content_type'] = ContentType.taxAlert.value;
            onSaveCallback(values);
        }
    }

    getForm() {
        return this.formReference;
    }

    render() {
        const {content} = this.props;
        const children = <Form.Item
            name="content_html"
            label="Content"
            rules={[
            ]}>
            <RichEditorInput/>
        </Form.Item>
        const val = ContentFormEditorConfigurator.getEditForm(content, this.onFinish.bind(this), children, true, true, ContentType.taxAlert);
        const form = val[0];
        this.formReference = val[1];
        return form;
    }
}

const mapStateToProps = ({ contentManagement }) => {
    return {contentManagement};
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(TaxAlertEditForm);