import React from "react";
import {hidePageLoader, showNetworkErrorMessage, showPageLoader} from "../../../../redux/slices/theme";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import ContentEditFormItem from "./editForm";
import {Button, Drawer} from "antd";
import {ContentType} from "../../../../configs/ContentTypes";

class SectionSubSectionFormContainer extends React.Component{
    editForm = React.createRef();
    onDismissDrawer() {
        if(this.props.onDismiss){
            this.props.onDismiss();
        }
    }
    onContentUpdated() {
        if(this.props.contentUpdateCallback){
            this.props.contentUpdateCallback();
        }
    }
    onSaveRequested() {
        const form = this.editForm.current.getForm();
        if(form) {
            form.submit();
        }
    }
    render() {
        const {contentTypeData, content, legislation} = this.props;
        /**
         * @type {ContentTypeData}
         */
        const {configurator} = contentTypeData;
        const editConfigurator = configurator.getFormEditor(this);
        const form = <ContentEditFormItem content={content} contentTypeData={contentTypeData} editConfigurator={editConfigurator} ref={this.editForm} contentUpdateCallback={e => this.onContentUpdated()}/>
        let subTitle = "";
        if(contentTypeData.value === ContentType.section.value){
            subTitle = `Section ${content.section_label || ""}`
        }
        const drawerTitle = <div>{legislation.title} <small style={{color: "#DC6900"}}>{subTitle}</small></div>
        const drawerFooter = <div className="text-right">
            <Button type="primary" htmlType="submit" onClick={e => this.onSaveRequested()}>
                Save
            </Button>
        </div>
        return <Drawer
            title={drawerTitle}
            placement="right"
            closable={true}
            width="50%"
            onClose={e => this.onDismissDrawer()}
            footer={drawerFooter}
            visible={true}>
            {form}
        </Drawer>
    }
}

const mapStateToProps = ({ contentManagement }) => {
    const {contentCategory} = contentManagement;
    const {contentTypes} = contentCategory;
    return {contentManagement, contentTypes};
}

const mapDispatchToProps = {
    showPageLoader, hidePageLoader, showNetworkErrorMessage
}

SectionSubSectionFormContainer.propTypes = {
    content: PropTypes.any,
    contentTypeData: PropTypes.any,
    legislation: PropTypes.any,
    contentUpdateCallback: PropTypes.func,
    onDismiss: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(SectionSubSectionFormContainer);