import React from "react";
import PropTypes from "prop-types";
import {hidePageLoader, showNetworkErrorMessage, showPageLoader} from "../../../../redux/slices/theme";
import {connect} from "react-redux";
import {Form, Input, Modal, notification} from "antd";
import lodash from "lodash";
import BackendContentService from "../../../../services/content_services";

class LegislationContentReindexDialog extends React.Component{
    formRef = React.createRef();
    onModalDismissed() {
        if(this.props.didRequestDismissal) {
            this.props.didRequestDismissal();
        }
    }

    onDoneClicked(){
        this.formRef.current.submit();
    }

    submitCallback(values){
        const contentMap = {}
        lodash.forIn(values, (value, key) => {
            contentMap[key.replaceAll("item_", "")] = value;
        })
        const requestData = {
            index_map: contentMap,
            content_type: this.props.contentType.value
        }


        const {showPageLoader, hidePageLoader, showNetworkErrorMessage, didCompleteReIndexing} = this.props;
        showPageLoader("Saving Indexes")
        BackendContentService.reindexLegislationContent(requestData).then((response) => {
            hidePageLoader();
            let notificationParam = {
                message: "Re-indexing completed",
                description: "Content has been successfully re-indexed"
            };
            notification.success(notificationParam);
            if(didCompleteReIndexing) {
                didCompleteReIndexing();
            }
        }).catch(error => {
            hidePageLoader();
            showNetworkErrorMessage(error);
        });

    }

    render() {
        const {title, items, labelCallback} = this.props;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        let formContent = {};
        let theItems = lodash.filter(items.slice(), (item) => !(item.isDraftMode === true || item.published === false));
        let fields = theItems.map(item => {
            formContent["item_"+item.id] = item.sort
            let label = labelCallback(item);
            let classes = "";
            // if(item.isDraftMode === true){
            //     classes += "is-draft";
            // }
            // if(item.published === false){
            //     classes += "unpublish";
            // }
            return <Form.Item className={classes} name={`item_${item.id}`} rules={[
                {
                    required: true,
                    message: 'Please enter a value',
                },
            ]} label={label} fieldKey={item.id} key={item.id}>
                <Input type={"number"} step={1}/>
            </Form.Item>
        })
        return <Modal
            className="leg-content-reindex-modal"
            visible={true}
            width="80%"
            title={title} closable={true}
            onOk={e => this.onDoneClicked()}
            destroyOnClose={true}
            okText="Re-Index"
            cancelText="Cancel"
            centered={true} onCancel={e => this.onModalDismissed()}>

            <Form className="content-edit-form"
                  ref={this.formRef}
                  {...formItemLayout}
                  name="register"
                  onFinish={e => this.submitCallback(e)}
                  initialValues={formContent}
                  scrollToFirstError>{fields}</Form>
        </Modal>
    }
}

LegislationContentReindexDialog.propTypes = {
    items: PropTypes.array,
    labelCallback: PropTypes.func,
    title: PropTypes.string,
    contentType: PropTypes.any,
    didCompleteReIndexing: PropTypes.func,
    didRequestDismissal: PropTypes.func
}

const mapStateToProps = ({ contentManagement }) => {
    return {contentManagement};
}

const mapDispatchToProps = {
    showPageLoader, hidePageLoader, showNetworkErrorMessage
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(LegislationContentReindexDialog);