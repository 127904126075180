import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'

export const APP_NAME = 'PwC Tax247 Admin';
export const AUTH_API_BASE_URL = env.AUTH_API_ENDPOINT_URL
export const CONTENT_API_BASE_URL = env.CONTENT_API_ENDPOINT_URL
export const APP_PREFIX_PATH = '/app';
export const USER_MANAGE_PREFIX_PATH = '/app/users';
export const CONTENT_MANAGE_PREFIX_PATH = '/app/content';
export const SETTINGS_PREFIX_PATH = '/app/settings';
export const AUTH_PREFIX_PATH = '/auth';

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'en',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '#ffffff',
	mobileNav: false,
	pageLoader: {
		show: false,
		message: null
	}
};
