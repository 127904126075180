import {authService, contentService} from 'auth/FetchInterceptor'
import {PATHS} from "../constants/services/auth";

const BackendContentService = {}

BackendContentService.loadContent = function (data){
    return contentService(PATHS.contentLoad, {
        method: 'POST',
        headers: {
        },
        data: data
    }).then(resp => resp.result);
}

BackendContentService.loadContentCategories = function (){
    return contentService(PATHS.listContentCategories, {
        method: 'GET',
        headers: {
        }
    }).then(resp => resp.result);
}

BackendContentService.createContentCategoryType = function (data){
    return contentService(PATHS.addContentCategoryType, {
        method: 'POST',
        headers: {
        },
        data: data
    }).then(resp => resp.result);
}

BackendContentService.saveContentCategory = function (data){
    return contentService(PATHS.saveContentCategory, {
        method: 'POST',
        headers: {
        },
        data: data
    }).then(resp => resp.result);
}

BackendContentService.getContentById = function (data){
    const url = PATHS.contentById.replaceAll(":type", data.contentType).replaceAll(":id", data.id);
    return contentService(url, {
        method: 'GET',
        headers: {
        },
    }).then(resp => resp.result);
}

BackendContentService.loadLegislationSections = function (legislationId, param){
    const url = PATHS.legislationSections.replaceAll(":legislationId", legislationId);
    return contentService(url, {
        method: 'GET',
        headers: {
            "tax247-only-published": false,
            "tax247-include-draft": true
        },
        params: param
    }).then(resp => resp);
}

BackendContentService.loadLegislationSubSections = function (sectionId, param){
    const url = PATHS.legislationSubSections.replaceAll(":sectionId", sectionId);
    return contentService(url, {
        method: 'GET',
        headers: {
            "tax247-only-published": false,
            "tax247-include-draft": true
        },
        params: param
    }).then(resp => resp);
}

BackendContentService.saveContent = function (content){

    return contentService(PATHS.saveContent, {
        method: 'POST',
        headers: {
        },
        data: content
    }).then(resp => resp);
}

BackendContentService.performContentAction = function (content) {
    /**
     * @type {EnumSymbol}
     */
    const action = content.action
    const data = {...content}
    data.action = action.value;

    return contentService(PATHS.performContentAction, {
        method: 'POST',
        headers: {
        },
        data: data
    }).then(resp => resp);
}

BackendContentService.getScheduleForLegislation = function (legislationId, page) {
    const url = PATHS.legislationSchedule.replaceAll(":legislationId", legislationId)
    const requestPage = page || 0;
    return contentService(url, {
        method: 'GET',
        headers: {
            "tax247-only-published": false,
            "tax247-include-draft": true
        },
        params: {
            page: requestPage,
            size: 50
        }
    }).then(resp => resp);
}

BackendContentService.getSubLegislationForLegislation = function (legislationId, page) {
    const url = PATHS.legislationSubSLegislation.replaceAll(":legislationId", legislationId)
    const requestPage = page || 0;
    return contentService(url, {
        method: 'GET',
        headers: {
            "tax247-only-published": false,
            "tax247-include-draft": true
        },
        params: {
            page: requestPage,
            size: 50
        }
    }).then(resp => resp);
}

BackendContentService.reindexLegislationContent = function (request) {
    const url = PATHS.legislationContentIndex
    return contentService(url, {
        method: 'POST',
        headers: {
        },
        data: request
    }).then(resp => resp);
}

BackendContentService.getLegislationSummary = function () {
    const url = PATHS.legislationSummary
    return contentService(url, {
        method: 'GET',
        headers: {
        },
        params: {
            includeSections: true,
            dictionaryPair: true
        }
    }).then(resp => resp);
}

BackendContentService.getLegislationSectionSummary = function (legislationId, section) {
    const url = PATHS.legislationSectionSummary.replaceAll(":legislationId", legislationId)
    return contentService(url, {
        method: 'GET',
        headers: {
        },
        params: {
            includeSections: true,
            includeSubsections: true,
            sections: `${section},${section}`,
            dictionaryPair: true
        }
    }).then(resp => resp);
}

BackendContentService.saveReferences = function (request) {
    const url = PATHS.saveReferences
    return contentService(url, {
        method: 'POST',
        headers: {
        },
        data: request
    }).then(resp => resp);
}

BackendContentService.loadDisclaimers = function (page, pageSize) {
    const url = PATHS.loadDisclaimers.replaceAll(":page", page).replaceAll(":size", pageSize)
    return contentService(url, {
        method: 'GET',
        headers: {
        },
    });
}

BackendContentService.saveDeclaimer = function (request) {
    const url = PATHS.saveDisclaimer;
    return contentService(url, {
        method: 'POST',
        headers: {
        },
        data: request
    });
}

BackendContentService.uploadFile = function (isImage, file, onUploadProgress) {
    let formData = new FormData();
    const header = {
        "Content-Type": "multipart/form-data"
    };
    if(isImage === true){
        header["image-only"] = "yes";
    }
    formData.append("file", file);
    return contentService.post(PATHS.uploadFile, formData, {
        headers: header,
        onUploadProgress
    });
}

export default BackendContentService;
