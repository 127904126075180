import lodash from "lodash";
export class EnumSymbol {
    sym;
    value;
    description;

    constructor(name, {value, description}, data) {
        this.sym = Symbol.for(name);
        if(!Object.is(value, undefined)) this.value  = value;
        if(description) this.description  = description;
        lodash.forIn(data, (val, key) => {
           this[key] = val;
           if(lodash.isFunction(val)){
               this[key] = val.bind(this);
           }
        });
        Object.freeze(this);
    }

    get display() {
        return this.description || Symbol.keyFor(this.sym);
    }

    toString() {
        return this.sym;
    }

    valueOf() {
        return this.value;
    }
}

export class Enum {
    constructor(enumLiterals) {
        for (let key in enumLiterals) {
            if(!enumLiterals[key]) throw new TypeError('each enum should have been initialized with atleast empty {} value');
            this[key] =  new EnumSymbol(key, enumLiterals[key], enumLiterals[key]);
        }
        Object.freeze(this);
    }

    symbols() {
        const items = [];
        for (let key in Object.keys(this)){
            items.push(this[key]);
        }
        return items;
    }

    keys() {
        return Object.keys(this);
    }

    contains(sym) {
        if (!(sym instanceof EnumSymbol)) return false;
        return this[Symbol.keyFor(sym.sym)] === sym;
    }
}