export const AUTH_TOKEN = 'auth_token'
export const USER_PROFILE = "user_profile"
export const USER_PERMISSIONS = "user_permissions"
const API_BACKEND_USER_ROOT = "/backend/user/"
const API_BACKEND_CONTENT_ROOT = "backend/fetch/"
const API_CONTENT_ROOT = "fetch/"
export const PATHS = {
    login: '/login',
    userProfile: '/session/profile',
    tax247UserSave: `${API_BACKEND_USER_ROOT}add`,
    tax247UserBulkUpload: `${API_BACKEND_USER_ROOT}user-bulk-upload`,
    tax247UserList: `${API_BACKEND_USER_ROOT}list`,
    tax247UserStats: `${API_BACKEND_USER_ROOT}statistics`,
    tax247OrganizationList: `${API_BACKEND_USER_ROOT}organization/list`,
    saveOrganization: `${API_BACKEND_USER_ROOT}organization/save`,
    subscriptionTypeList: `${API_BACKEND_USER_ROOT}subscription/types`,
    assignSubscription: `${API_BACKEND_USER_ROOT}subscription/assign`,
    moveUserToOrganization: `${API_BACKEND_USER_ROOT}move-to-organization`,
    acceptedCount: `${API_BACKEND_USER_ROOT}disclaimer/:disclaimerId/count`,

    contentLoad: `${API_BACKEND_CONTENT_ROOT}list`,
    saveContent: `${API_BACKEND_CONTENT_ROOT}save-content`,
    contentById: `${API_CONTENT_ROOT}:type/item/:id`,
    legislationSections: `${API_CONTENT_ROOT}legislation/:legislationId/sections`,
    legislationSubSections: `${API_CONTENT_ROOT}legislation/section/:sectionId/subSections`,
    legislationSchedule: `${API_CONTENT_ROOT}legislation/:legislationId/schedule`,
    legislationSubSLegislation: `${API_CONTENT_ROOT}legislation/:legislationId/sublegislation`,
    legislationSummary: `${API_CONTENT_ROOT}legislation/summary`,
    legislationSectionSummary: `${API_CONTENT_ROOT}legislation/summary/:legislationId`,

    uploadFile: `${API_BACKEND_CONTENT_ROOT}upload-public`,
    legislationContentIndex: `${API_BACKEND_CONTENT_ROOT}reindex`,
    listContentCategories: `${API_BACKEND_CONTENT_ROOT}content-categories`,
    addContentCategoryType: `${API_BACKEND_CONTENT_ROOT}category-type`,
    saveContentCategory: `${API_BACKEND_CONTENT_ROOT}category-update`,
    performContentAction: `${API_BACKEND_CONTENT_ROOT}group-action`,
    saveReferences: `${API_BACKEND_CONTENT_ROOT}save-references`,

    loadDisclaimers: `${API_BACKEND_CONTENT_ROOT}disclaimers/page/:page/size/:size`,
    saveDisclaimer: `${API_BACKEND_CONTENT_ROOT}disclaimers/save`
}