class StringUtils {
    static compareStrings(one, two) {
        const itemOne = (one || "").toUpperCase(); // ignore upper and lowercase
        const itemTwo = (two || "").toUpperCase(); // ignore upper and lowercase
        if (itemOne < itemTwo) {
            return -1;
        }
        if (itemOne > itemTwo) {
            return 1;
        }

        // names must be equal
        return 0;
    }
}

export default StringUtils;