import React from "react";
import {connect} from "react-redux";
import {Col, DatePicker, Form, Input, Row} from "antd";
import RichEditorInput from "../../../reuseable/rich_editor";
import {ContentFormEditorConfigurator} from "../configurator/base";
import {ContentType} from "../../../../configs/ContentTypes";

class TaxtreatyEditForm extends React.Component{

    dateFields = ['effective_date', 'date_of_enforcement', 'date_of_signing'];
    ownFields = ["effective_date", "date_of_enforcement", "date_of_signing", "place_of_signing"];
    onFinish(values){
        const {onSaveCallback} = this.props;
        let valuesToSave = ContentFormEditorConfigurator.repopulateDateOnValues(this.dateFields, values);
        valuesToSave = ContentFormEditorConfigurator.moveOwnProperties(this.ownFields, "tax_treaty", valuesToSave);
        if(onSaveCallback){
            valuesToSave['content_type'] = ContentType.taxTreaty.value;
            onSaveCallback(valuesToSave);
        }
    }

    getForm() {
        return this.formReference;
    }

    render() {
        const {content} = this.props;
        const formContent = ContentFormEditorConfigurator.prepareDateField(this.dateFields, content)
        let dualWrappedCol = {
            xs: { span: 24 },
            sm: { span: 14 }
        };
        let dualWrappedLabel = {
            xs: { span: 24 },
            sm: { span: 10 }
        };
        const children = <div>
            <Form.Item
                name="place_of_signing"
                label="Place of Signing"
                rules={[]}>
                <Input />
            </Form.Item>
            <Row>
                <Col span={5} offset={4}>
                    <Form.Item label="Effective Date" name="effective_date_moment"
                               labelCol={dualWrappedLabel}
                               wrapperCol={dualWrappedCol}>
                        <DatePicker />
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item label="Date of Enforcement" name="date_of_enforcement_moment"
                               labelCol={dualWrappedLabel}
                               wrapperCol={dualWrappedCol}>
                        <DatePicker />
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item label="Date of Signing" name="date_of_signing_moment"
                               labelCol={dualWrappedLabel}
                               wrapperCol={dualWrappedCol}>
                        <DatePicker />
                    </Form.Item>
                </Col>
            </Row>



            <Form.Item
                name="content_html"
                label="Content"
                rules={[
                ]}>
                <RichEditorInput/>
            </Form.Item>
        </div>
        const val = ContentFormEditorConfigurator.getEditForm(formContent, this.onFinish.bind(this), children, true, true, ContentType.taxTreaty)
        const form = val[0];
        this.formReference = val[1];
        return form;
    }
}

const mapStateToProps = ({ contentManagement }) => {
    return {contentManagement};
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(TaxtreatyEditForm);