import React from "react";
import {connect} from "react-redux";
import {DatePicker, Form} from "antd";
import RichEditorInput from "../../../reuseable/rich_editor";
import {ContentFormEditorConfigurator} from "../configurator/base";
import {ContentType} from "../../../../configs/ContentTypes";

class Covid19EditForm extends React.Component{

    dateFields = ['issued_date'];
    ownFields = ["issued_date"];
    onFinish(values){
        const {onSaveCallback} = this.props;
        let valuesToSave = ContentFormEditorConfigurator.repopulateDateOnValues(this.dateFields, values);
        valuesToSave = ContentFormEditorConfigurator.moveOwnProperties(this.ownFields, "covid19", valuesToSave);
        if(onSaveCallback){
            valuesToSave['content_type'] = ContentType.covid19.value;
            onSaveCallback(valuesToSave);
        }
    }

    getForm() {
        return this.formReference;
    }

    render() {
        const {content} = this.props;
        const formContent = ContentFormEditorConfigurator.prepareDateField(this.dateFields, content)
        const children = <div>
            <Form.Item label="Effective Date" name="issued_date_moment">
                <DatePicker />
            </Form.Item>
            <Form.Item
                name="content_html"
                label="Content"
                rules={[
                ]}>
                <RichEditorInput/>
            </Form.Item>
        </div>
        const val = ContentFormEditorConfigurator.getEditForm(formContent, this.onFinish.bind(this), children, true, true, ContentType.covid19)
        const form = val[0];
        this.formReference = val[1];
        return form;
    }
}

const mapStateToProps = ({ contentManagement }) => {
    return {contentManagement};
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(Covid19EditForm);