import React from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import utils from "../../utils";
import navigationConfig from "../../configs/NavigationConfig";
import {Alert} from "antd";

const RolePermission = (props) => {
    const { location, children, permissions, accessDeniedChild, auth,
        onPermissionResolved, accessDeniedMargin, accessDeniedMessage } = props;
    const currentRouteInfo = utils.getRouteInfo(navigationConfig, location.pathname)
    const checkedPermission = permissions || currentRouteInfo?.permissions;
    var hasPermission = false;
    var child = <></>
    if(checkedPermission && checkedPermission.length > 0) {
        if(utils.arrayContainsArray(auth.userPermissions, checkedPermission)) {
            hasPermission = true;
            child = <>{children}</>;
        }
        else{
            hasPermission = false;
            child = <>{accessDeniedChild || <Alert style={{marginLeft: `${accessDeniedMargin || 0}px`, marginRight: `${accessDeniedMargin || 0}px`}}
                message="Access Denied"
                description={`${accessDeniedMessage || "You do not have access to this content"}`}
                type="error"
            />}</>;
        }
    }
    else{
        hasPermission = true;
        child = <>{children}</>;
    }
    if(onPermissionResolved){
        onPermissionResolved(hasPermission);
    }
    return child
}

RolePermission.propTypes = {
    permissions: PropTypes.arrayOf(PropTypes.string),
    accessDeniedChild: PropTypes.element,
    accessDeniedMargin: PropTypes.number,
    accessDeniedMessage: PropTypes.string,
    onPermissionResolved: PropTypes.func
}

const mapStateToProps = ({ theme, auth }) => {
    return { auth }
};

export default withRouter(connect(mapStateToProps)(RolePermission));