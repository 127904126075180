import React from "react";
import {connect} from "react-redux";
import {Card, Col, DatePicker, Form, Input, Row} from "antd";
import RichEditorInput from "../../../reuseable/rich_editor";
import {ContentFormEditorConfigurator} from "../configurator/base";
import {ContentType} from "../../../../configs/ContentTypes";

class CaselawEditForm extends React.Component{

    formReference;
    dateFields = ["judgement_date"];
    ownFields = ["judgement_date", "case_summary_html", "judgement_html", "citation"];
    constructor(props) {
        super(props);
        this.state = {
            activeKey: 'case',
            judgement: null,
            caseSummary: null
        }
    }

    getForm() {
        return this.form;
    }


    onFinish(values){
        const {content} = this.props;
        if(!values['case_summary_html']) {
            values['case_summary_html'] = this.state.caseSummary || content.case_summary_html;
        }
        if(!values['judgement_html']) {
            values['judgement_html'] = this.state.judgement || content.judgement_html;
        }
        let valuesToSave = ContentFormEditorConfigurator.repopulateDateOnValues(this.dateFields, values);
        valuesToSave = ContentFormEditorConfigurator.moveOwnProperties(this.ownFields, "case_law", valuesToSave);
        const {onSaveCallback} = this.props;
        if(onSaveCallback){
            valuesToSave['content_type'] = ContentType.caseLaw.value;
            onSaveCallback(valuesToSave);
        }
    }

    tabWasChanged(key) {
        const newState = { activeKey: key }
        if(key === "case") {
            newState.judgement = this.formReference.current.getFieldValue("judgement_html")
        }
        else{
            newState.caseSummary = this.formReference.current.getFieldValue("case_summary_html")
        }
        this.setState(newState);
    }

    render() {
        const {content} = this.props;

        const formContent = ContentFormEditorConfigurator.prepareDateField(this.dateFields, content);

        const tabList = [
            {
                key: 'case',
                tab: 'Case Summary',
            },
            {
                key: 'judgement',
                tab: 'Judgement Summary',
            },
        ];


        let activeKey = this.state.activeKey;
        let wrapperCol = {
            xs: { span: 24 },
            sm: { span: 24 }
        };
        const contentList = {
            'case': <Form.Item wrapperCol={wrapperCol}
                name="case_summary_html"
                label=""
                rules={[
                    {
                        required: true,
                        message: 'Please enter a case summary',
                    },
                ]}>
                <RichEditorInput/>
            </Form.Item>,
            'judgement': <Form.Item wrapperCol={wrapperCol}
                name="judgement_html"
                label=""
                rules={[
                    {
                        required: true,
                        message: 'Please enter a case judgement!',
                    },
                ]}>
                <RichEditorInput/>
            </Form.Item>,
        };
        const card = <Row>
            <Col span={16} offset={4}>
                <Card tabList={tabList} onTabChange={e => this.tabWasChanged(e)}>
                    {contentList[activeKey]}
                </Card>
            </Col>
        </Row>

        const children = <>
            <Form.Item
                name="citation"
                label="Citation"
                rules={[]}>
                <Input />
            </Form.Item>
            <Form.Item label="Judgement Date" name="judgement_date_moment">
                <DatePicker />
            </Form.Item>
            {card}
        </>;
        const result = ContentFormEditorConfigurator.getEditForm(formContent, this.onFinish.bind(this), children, true, true, ContentType.caseLaw)
        this.formReference = result[1];
        return result[0];
    }
}

const mapStateToProps = ({ contentManagement }) => {
    return {contentManagement};
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(CaselawEditForm);