import DefaultConfigurator, {ContentDetailsConfigurator} from "./base";
import {Card, Col, Row} from "antd";
import React from "react";
import { Typography } from 'antd';
import moment from 'moment';
import utils from "../../../../utils";
import dayjs from "dayjs";

const { Text, Title } = Typography;

export default class Covid19Configurator extends DefaultConfigurator{
    updateColumns(columns){
        const enforcement = {
            title: 'Issued Date',
            dataIndex: 'issued_date',
            key: 'issued_date',
            sorter: (a, b) => utils.string.compareStrings(`${a.issued_date}`, `${b.date_of_enforcement}`),
            render: (date, record) => {
                if (date){
                    return moment(date).format("DD MMM, YYYY")
                }
                else{
                    return <div/>
                }
            }
        }
        columns.splice(1, 0, enforcement);
        return columns;
    }

    createDetailsConfigurator(component){
        return new DetailsConfigurator(component);
    }
}

class DetailsConfigurator extends ContentDetailsConfigurator{
    getPostTitleComponent(content) {
        let date = "";
        if(content.issued_date) {
            date = moment(content.issued_date).format("YYYY-MM-DD")
            date = <Text><small>Issued Date: {date}</small><br/></Text>
        }
        return <>{date}</>
    }
}