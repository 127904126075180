import React from "react";
import {connect} from "react-redux";
import {Form} from "antd";
import RichEditorInput from "../../../reuseable/rich_editor";
import {ContentFormEditorConfigurator} from "../configurator/base";
import {ContentType} from "../../../../configs/ContentTypes";

class SubLegislationEditForm extends React.Component{

    dateFields = [];
    ownFields = ["legislation"];
    onFinish(values){
        const {onSaveCallback} = this.props;
        let valuesToSave = ContentFormEditorConfigurator.repopulateDateOnValues(this.dateFields, values);
        valuesToSave = ContentFormEditorConfigurator.moveOwnProperties(this.ownFields, "sub_legislation", valuesToSave);
        if(onSaveCallback){
            valuesToSave['content_type'] = ContentType.subLegislation.value;
            onSaveCallback(valuesToSave);
        }
    }

    getForm() {
        return this.formReference;
    }

    render() {
        const {content} = this.props;
        const formContent = ContentFormEditorConfigurator.prepareDateField(this.dateFields, content)
        const children = <div>
            <Form.Item
                name="content_html"
                label="Content"
                rules={[
                    {
                        required: true,
                        message: 'Please input a content',
                    }
                ]}>
                <RichEditorInput/>
            </Form.Item>
            <Form.Item hidden={true} name="legislation"/>
        </div>
        const val = ContentFormEditorConfigurator.getEditForm(formContent, this.onFinish.bind(this), children, true, true, ContentType.subLegislation, false)
        const form = val[0];
        this.formReference = val[1];
        return form;
    }
}

const mapStateToProps = ({ contentManagement }) => {
    return {contentManagement};
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(SubLegislationEditForm);