import React from "react";
import {connect} from "react-redux";
import {ContentFormEditorConfigurator} from "../configurator/base";
import {ContentType} from "../../../../configs/ContentTypes";
import {Form, Input} from "antd";

class SubSectionEditForm extends React.Component{

    dateFields = [];
    ownFields = ["sub_section_number", "sub_section_number_string", "sub_section_sort", "content", "section"];
    onFinish(values){
        const {onSaveCallback} = this.props;
        if (!values["sub_section_number"]) {
            values["sub_section_number"] = parseInt(values['sub_section_number_string']);
        }

        let valuesToSave = ContentFormEditorConfigurator.repopulateDateOnValues(this.dateFields, values);
        valuesToSave = ContentFormEditorConfigurator.moveOwnProperties(this.ownFields, "sub_section", valuesToSave);
        if(onSaveCallback){
            valuesToSave['content_type'] = ContentType.subSection.value;
            onSaveCallback(valuesToSave);
        }
    }

    getForm() {
        return this.formReference;
    }

    render() {
        const {content} = this.props;
        const formContent = ContentFormEditorConfigurator.prepareDateField(this.dateFields, content)
        const children = <div>
            <Form.Item name="sub_section_number_string"
                       label="Sub Section Number"
                       rules={[
                           {
                               required: true,
                               message: 'Enter a subsection number',
                           },
                       ]}>
                <Input/>
            </Form.Item>
            <Form.Item
                name="content"
                label="Sub Section Content"
                rules={[
                    {
                        required: true,
                        message: 'Please enter content',
                    },
                ]}>
                <Input.TextArea rows={10} />
            </Form.Item>
            <Form.Item hidden={true} name="sub_section_sort"/>
            <Form.Item hidden={true} name="section"/>
            <Form.Item hidden={true} name="sub_section_number"/>
            <Form.Item hidden={true} name="title"/>
        </div>
        const val = ContentFormEditorConfigurator.getEditForm(formContent, this.onFinish.bind(this), children, false, false, ContentType.subSection, false)
        const form = val[0];
        this.formReference = val[1];
        return form;
    }
}

const mapStateToProps = ({ contentManagement }) => {
    return {contentManagement};
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(SubSectionEditForm);