import {ContentDetailsConfigurator} from "./base";
import {Card, Col, Menu, notification, Row, Typography} from "antd";
import dayjs from "dayjs";
import LegislationSection from "../details/legislation_section";
import LegislationSubSection from "../details/legislation_subsection";
import React from "react";
import SectionSubSectionFormContainer from "../edit/section_sub_section";
import {ContentType} from "../../../../configs/ContentTypes";
import {BulkListMenuKey, LegislationMenuKey} from "../bulk_menu_keys";
import LegislationSubLegislation from "../schedule_subleg";
import lodash from "lodash";
import LegislationContentReindexDialog from "../details/reindex_dialog";
import BackendContentService from "../../../../services/content_services";

const { Text } = Typography;

export class LegislationDetailsConfigurator extends ContentDetailsConfigurator{

    getPostTitleComponent(content){
        return <Text type="secondary">{content.acronym}</Text>
    }

    onDetailsMenuSelected(menuKey, selectedItems, contentType, component, callback){
        const handled = super.onDetailsMenuSelected(menuKey, selectedItems, contentType, component, callback);
        if(!handled) {
            let newState = {};
            if (menuKey === LegislationMenuKey.showSubLegislation){
                newState.showSubLegSchedule = ContentType.subLegislation
            }
            else if(menuKey === LegislationMenuKey.showSchedules){
                newState.showSubLegSchedule = ContentType.schedule
            }
            this.component.setState(newState);
        }
    }

    onDismissSubLegislationSchedule() {
        this.component.setState({
            showSubLegSchedule: null
        })
    }

    buildDetailsTitle(title, postTitle, categories, content){
        const currentTitle = super.buildDetailsTitle(title, postTitle, categories, content);
        const meta = this.getLegislationMeta(content);
        return <div>
            <div className="content-title-wrapper">
                {currentTitle}
                <hr/>
                <Text type={"secondary"}>{content.synopsis}</Text>
            </div>
            {meta}
        </div>
    }

    updateMenu(content, menuList){
        menuList.push(
            <Menu.Item key={LegislationMenuKey.showSchedules} >
                Schedule
            </Menu.Item>
        )
        menuList.push(
            <Menu.Item key={LegislationMenuKey.showSubLegislation} >
                Sub Legislation
            </Menu.Item>
        )
        if(content.isDraftMode){
            const draft = <Menu.Item key="main" selectable={false} disabled className="legislation-draft-mode">
                In Draft Mode
            </Menu.Item>
            const publishDraft = <Menu.Item key={BulkListMenuKey.publishDraft}>
                Publish Draft
            </Menu.Item>
            menuList.splice(0, 0, draft);
            // menuList.push(<Menu.Divider/>);
            menuList.push(publishDraft);
        }
        if(!content.published){
            const publishItem = <Menu.Item key={BulkListMenuKey.publish}>
                Publish Item
            </Menu.Item>
            menuList.push(publishItem);
        }
        return menuList;
    }

    getLegislationMeta(content){
        const date = dayjs(content.commencement_date).format('DD MMM YYYY')
        let comp = content.mode_of_compliance;
        let enforcer = content.law_enforcer;
        if(comp && comp.length <= 0){
            comp = "nil";
        }
        if(enforcer && enforcer.length <= 0){
            enforcer = "nil";
        }
        return <Card className={"meta"}>
            <Row>
                <Col span={8} className={"firstThird"}>
                    <div>Commencement Date</div>
                    <div>{date}</div>
                </Col>
                <Col span={8} className={"secondThird"}>
                    <div>Mode Of Compliance</div>
                    <div>{comp}</div>
                </Col>
                <Col span={8} className={"lastThird"}>
                    <div>Law Enforcer</div>
                    <div>{enforcer}</div>
                </Col>
            </Row>
        </Card>
    }

    onSectionsSelected(sections) {
        const newState = {
            activeSections: sections
        };
        this.component.setState()
        if(sections && sections.length === 1){
            //load new sections
            newState.currentSection = sections[0];
        }
        else{
            newState.currentSection = null;
        }
        this.component.setState(newState);
    }

    onSectionMenuItemSelected(menuKey, sections, count) {
        /**
         *
         * @type {LegislationConfigurator}
         */
        const configurator = this.baseConfigurator;
        const callback = (contentType) => {
            this.reloadSections();
        }
        if (!configurator.handleContentListMenuClick(menuKey, sections, ContentType.section, this.component, callback)) {
            const newState = {
                selectedSections: sections,
                sectionAction: menuKey,
                selectedSubSections: null,
                subSectionAction: null,
                allSectionCount: count
            };
            if (menuKey === BulkListMenuKey.reindex){
                newState.selectedSections = null;
                newState.allSectionList = sections;
                newState.allSubSectionList = null;
            }
            this.component.setState(newState);
        }

    }

    onSubSectionMenuItemSelected(menuKey, subSections, count) {
        /**
         *
         * @type {LegislationConfigurator}
         */
        const configurator = this.baseConfigurator;
        const callback = (contentType) => {
            this.reloadSubSections();
        }
        if (!configurator.handleContentListMenuClick(menuKey, subSections, ContentType.subSection, this.component, callback)) {
            const newState = {
                selectedSections: null,
                sectionAction: null,
                selectedSubSections: subSections,
                subSectionAction: menuKey,
                allSubSectionCount: count
            };
            if (menuKey === BulkListMenuKey.reindex){
                newState.selectedSubSections = null;
                newState.allSubSectionList = subSections;
                newState.allSectionList = null;
            }
            this.component.setState(newState);
        }
    }


    getSections(content){
        if(!this.component.sectionReference) {
            this.component.sectionReference = React.createRef();
        }
        return <div className="section-wrapper">
            <LegislationSection legislation={content} didSelectSection={e => this.onSectionsSelected(e)} onSectionMenuItemClicked={(e, s, c) => this.onSectionMenuItemSelected(e, s, c)} ref={this.component.sectionReference}/>
        </div>
    }

    getSubSections(content){
        if(!this.component.subSectionReference) {
            this.component.subSectionReference = React.createRef();
        }
        const {currentSection} = this.component.state;
        return <div className="sub-section-wrapper">
            <LegislationSubSection
                legislation={content} section={currentSection}
                onSubSectionMenuItemClicked={(e, s, c) => this.onSubSectionMenuItemSelected(e, s, c)}
                onDeleteReferenceRequested={(ref, val, cType, callback) => this.didRequestDeleteReference(ref, val, cType, callback)}
                onSaveReferenceRequested={(ref, val, cType, callback) => this.didRequestSaveReference(ref, val, cType, callback)}
                ref={this.component.subSectionReference}/>
        </div>
    }

    didRequestDeleteReference(reference, value, contentType, callback) {
        let references = reference.references
        if(lodash.isArray(references) === false){
            references = [];
        }
        lodash.remove(references, (ref) => ref.identity === value.identity);

        const requestObject = {
            references: references,
            contentType: contentType,
            contentId: reference.id
        };

        this.component.props.showPageLoader("Saving references");
        BackendContentService.saveReferences(requestObject).then((response) => {
            this.component.props.hidePageLoader();
            let notificationParam = {
                message: "References saved",
                description: "References has been successfully saved"
            };
            notification.success(notificationParam);
            callback();
        }).catch(error => {
            this.component.props.hidePageLoader();
            this.component.props.showNetworkErrorMessage(error)
        });
    }

    didRequestSaveReference(reference, value, contentType, callback) {
        let references = reference.references
        if(lodash.isArray(references) === false){
            references = [];
        }

        const index = lodash.findIndex(references, (ref) => ref.identity === value.identity);
        if(index >= 0){
            references[index] = value;
        }
        else{
            references.push(value);
        }
        const requestObject = {
            references: references,
            contentType: contentType,
            contentId: reference.id
        };

        this.component.props.showPageLoader("Saving references");
        BackendContentService.saveReferences(requestObject).then((response) => {
            this.component.props.hidePageLoader();
            let notificationParam = {
                message: "References saved",
                description: "References has been successfully saved"
            };
            notification.success(notificationParam);
            callback();
        }).catch(error => {
            this.component.props.hidePageLoader();
            this.component.props.showNetworkErrorMessage(error)
        });
    }

    legislationSectionContentUpdated(reload) {
        const newState = {
            selectedSections: null,
            sectionAction: null,
            selectedSubSections: null,
            subSectionAction: null,
            allSubSectionCount: null,
            allSectionCount: null,
            allSubSectionList: null,
            allSectionList: null
        };
        this.component.setState(newState);
        if(reload === false){
        }
        else{
            this.reloadSections();
        }
    }

    reloadSections(){
        if(this.component.sectionReference) {
            this.component.sectionReference.current.reloadSections()
        }
        this.reloadSubSections();
    }

    reloadSubSections(){
        if(this.component.subSectionReference) {
            this.component.subSectionReference.current.reloadCurrentSubSections()
        }
    }

    legislationSubSectionContentUpdated(reload) {
        const newState = {
            selectedSections: null,
            sectionAction: null,
            selectedSubSections: null,
            subSectionAction: null,
            allSubSectionCount: null,
            allSectionCount: null,
            allSubSectionList: null,
            allSectionList: null
        };
        this.component.setState(newState);
        if(reload === false){
        }
        else{
            this.reloadSubSections();
        }
    }

    onDismissDrawer() {
        const newState = {
            selectedSections: null,
            sectionAction: null,
            selectedSubSections: null,
            subSectionAction: null,
            allSubSectionCount: null,
            allSectionCount: null,
            allSubSectionList: null,
            allSectionList: null
        };
        this.component.setState(newState);
    }

    renderDetailsContent(content){
        const section = this.getSections(content);
        const subsec = this.getSubSections(content);
        const actions = [];
        const {selectedSections, sectionAction, currentSection, selectedSubSections, subSectionAction, showSubLegSchedule} = this.component.state;
        if(sectionAction === BulkListMenuKey.edit && selectedSections && selectedSections.length > 0){
            const sectionContent = <SectionSubSectionFormContainer key="section_edit" legislation={content}
                                                                   onDismiss={e => this.onDismissDrawer()}
                                                                   contentUpdateCallback={e => this.legislationSectionContentUpdated()}
                                                                   content={selectedSections[0]} contentTypeData={ContentType.section}/>
            actions.push(sectionContent)
        }
        else if(sectionAction === BulkListMenuKey.add){
            let nextCount = this.component.state.allSectionCount || 0;
            nextCount += 1;
            let data = {section_label: `${nextCount}`, section_sort:nextCount};
            data.published = content.published
            data.isDraftMode = content.isDraftMode
            data.legislation = content.id;
            data.access_level = 0;
            const sectionContent = <SectionSubSectionFormContainer key="section_add" legislation={content}
                                                                   onDismiss={e => this.onDismissDrawer()}
                                                                   contentUpdateCallback={e => this.legislationSectionContentUpdated()}
                                                                   content={data} contentTypeData={ContentType.section}/>
            actions.push(sectionContent)
        }
        else if(currentSection && subSectionAction === BulkListMenuKey.edit && selectedSubSections.length > 0) {
            const sectionContent = <SectionSubSectionFormContainer key="sub_section_edit" legislation={content}
                                                                   onDismiss={e => this.onDismissDrawer()}
                                                                   contentUpdateCallback={e => this.legislationSubSectionContentUpdated()}
                                                                   content={selectedSubSections[0]} contentTypeData={ContentType.subSection}/>
            actions.push(sectionContent)
        }
        else if (currentSection && subSectionAction === BulkListMenuKey.add) {
            let nextCount = this.component.state.allSubSectionCount || 0;
            nextCount += 1;
            let data = {sub_section_number_string: `${nextCount}`, sub_section_sort:nextCount, section: currentSection.id};
            data.published = currentSection.published;
            data.isDraftMode = currentSection.isDraftMode;
            if(currentSection.isDraftMode === false && content.isDraftMode === true) {
                data.isDraftMode = content.isDraftMode
            }
            // data.section = content.id;
            data.access_level = 0;
            data.title = `${content.acronym || ""} - ${currentSection.section_number} (${nextCount})`
            const sectionContent = <SectionSubSectionFormContainer key="sub_section_add" legislation={content}
                                                                   onDismiss={e => this.onDismissDrawer()}
                                                                   contentUpdateCallback={e => this.legislationSubSectionContentUpdated()}
                                                                   content={data} contentTypeData={ContentType.subSection}/>
            actions.push(sectionContent)
        }
        else if(sectionAction === BulkListMenuKey.reindex ){
            const {allSectionList} = this.component.state;
            let items = allSectionList.map(item => {
                return {id: item.id, sort: item.section_sort, published: item.published, isDraftMode: item.isDraftMode, label: item.section_label}
            });
            let content = <LegislationContentReindexDialog
                labelCallback={e => `Section Number ${e.label}`} didRequestDismissal={e => this.legislationSectionContentUpdated(false)}
                didCompleteReIndexing={e => this.legislationSectionContentUpdated()}
                title="Re-Index Section" items={items} contentType={ContentType.section}/>
            actions.push(content);
        }
        else if(subSectionAction === BulkListMenuKey.reindex ){
            const {allSubSectionList} = this.component.state;
            let items = allSubSectionList.map(item => {
                return {id: item.id, sort: item.sub_section_sort, published: item.published, isDraftMode: item.isDraftMode, label: item.sub_section_number_string}
            });
            let content = <LegislationContentReindexDialog
                labelCallback={e => `Sub Section Number ${e.label}`} didRequestDismissal={e => this.legislationSubSectionContentUpdated(false)}
                didCompleteReIndexing={e => this.legislationSubSectionContentUpdated()}
                title="Re-Index Sub Section" items={items} contentType={ContentType.subSection}/>
            actions.push(content);
        }
        else if (showSubLegSchedule) {
            const action = <LegislationSubLegislation
                key="sub-leg-schedule" legislationContent={content}
                detailsConfigurator={this}
                contentTypeData={showSubLegSchedule}
                onDismissRequested={e => this.onDismissSubLegislationSchedule()}/>
            actions.push(action);
        }
        return <div>
            {section}
            {subsec}
            {actions}
        </div>
    }
}