import { 
  DashboardOutlined, UserOutlined,
  AppstoreAddOutlined,ApartmentOutlined,
  DotChartOutlined, MessageOutlined,
  VerticalAlignTopOutlined, RadarChartOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH, USER_MANAGE_PREFIX_PATH, CONTENT_MANAGE_PREFIX_PATH, SETTINGS_PREFIX_PATH } from 'configs/AppConfig'
import { FaUserClock, FaSuitcase, FaAtom, FaBoxOpen, FaMicroscope } from 'react-icons/fa';
import {MdGavel, MdAlarmAdd, MdEventNote, MdGraphicEq} from "react-icons/md";
import React from "react";
import {PERMISSIONS} from "../constants/Permissions";
import {ContentType} from "./ContentTypes";


const dashBoardNavTree = [{
  key: 'dashboards',
  path: `${APP_PREFIX_PATH}/dashboards`,
  title: 'sidenav.dashboard',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'dashboards-home',
      path: `${APP_PREFIX_PATH}/dashboards/home`,
      title: 'sidenav.dashboard.default',
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'dashboards-analytic',
      path: `${APP_PREFIX_PATH}/dashboards/analytic`,
      title: 'sidenav.dashboard.analytic',
      icon: DotChartOutlined,
      breadcrumb: false,
      submenu: []
    }
  ]
}];

const userNavTree = [{
  key: "users",
  path: `${USER_MANAGE_PREFIX_PATH}`,
  title: 'sidenav.users',
  icon: UserOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'users-list',
      path: `${USER_MANAGE_PREFIX_PATH}/list`,
      title: 'sidenav.users.list',
      pageTitle: 'sidenav.users.userManagement',
      icon: UserOutlined,
      breadcrumb: true,
      submenu: [],
      permissions: [PERMISSIONS.manageTax247Users]
    },
    {
      key: 'users-org-list',
      path: `${USER_MANAGE_PREFIX_PATH}/list-organization`,
      title: 'sidenav.users.organization',
      pageTitle: 'sidenav.users.organizationManagement',
      icon: ApartmentOutlined,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'messaging',
      path: `${USER_MANAGE_PREFIX_PATH}/messaging`,
      title: 'sidenav.users.messaging',
      icon: MessageOutlined,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'subscription-type',
      path: `${USER_MANAGE_PREFIX_PATH}/subscription-types`,
      title: 'sidenav.users.subscriptionTypes',
      icon: VerticalAlignTopOutlined,
      breadcrumb: false,
      submenu: []
    }
  ]
}]

const contentNavTree = [{
  key: "content",
  path: `${CONTENT_MANAGE_PREFIX_PATH}`,
  title: 'sidenav.content',
  icon: AppstoreAddOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'news-list',
      path: `${CONTENT_MANAGE_PREFIX_PATH}/${ContentType.news.path()}`,
      title: 'sidenav.content.news.list',
      rawIcon: <MdAlarmAdd size={'1.3em'}/>,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'legislation-list',
      path: `${CONTENT_MANAGE_PREFIX_PATH}/${ContentType.legislation.path()}`,
      title: 'sidenav.content.legislation.list',
      rawIcon: <MdGavel size={'1.3em'}/>,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'taxalert-list',
      path: `${CONTENT_MANAGE_PREFIX_PATH}/${ContentType.taxAlert.path()}`,
      title: 'sidenav.content.taxalert.list',
      rawIcon: <FaUserClock size={'1.3em'}/>,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'caselaw-list',
      path: `${CONTENT_MANAGE_PREFIX_PATH}/${ContentType.caseLaw.path()}`,
      title: 'sidenav.content.caselaw.list',
      rawIcon: <FaSuitcase size={'1.3em'}/>,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'circular-list',
      path: `${CONTENT_MANAGE_PREFIX_PATH}/${ContentType.circular.path()}`,
      title: 'sidenav.content.circular.list',
      rawIcon: <FaAtom size={'1.3em'}/>,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'taxtreaty-list',
      path: `${CONTENT_MANAGE_PREFIX_PATH}/${ContentType.taxTreaty.path()}`,
      title: 'sidenav.content.taxtreaty.list',
      rawIcon: <MdGraphicEq size={'1.3em'}/>,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'event-list',
      path: `${CONTENT_MANAGE_PREFIX_PATH}/${ContentType.event.path()}`,
      title: 'sidenav.content.event.list',
      rawIcon: <MdEventNote size={'1.3em'}/>,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'featured-content-list',
      path: `${CONTENT_MANAGE_PREFIX_PATH}/${ContentType.featuredContent.path()}`,
      title: 'sidenav.content.featuredContent.list',
      rawIcon: <FaBoxOpen size={'1.3em'}/>,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'covid19-list',
      path: `${CONTENT_MANAGE_PREFIX_PATH}/${ContentType.covid19.path()}`,
      title: 'sidenav.content.covid.list',
      rawIcon: <FaMicroscope size={'1.3em'}/>,
      breadcrumb: true,
      submenu: []
    }
  ]
}];

const settingsTree = [
  {
    key: "settings",
    path: `${SETTINGS_PREFIX_PATH}`,
    title: 'sidenav.settings',
    icon: AppstoreAddOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'content-categories',
        path: `${SETTINGS_PREFIX_PATH}/content-categories`,
        title: 'sidenav.settings.content-categories',
        rawIcon: <RadarChartOutlined />,
        breadcrumb: true,
        submenu: []
      }, {
        key: 'app-disclaimer',
        path: `${SETTINGS_PREFIX_PATH}/app-disclaimer`,
        title: 'sidenav.settings.disclaimer',
        rawIcon: <RadarChartOutlined />,
        breadcrumb: true,
        submenu: []
      }
    ]
  }
];


const navigationConfig = [
  ...dashBoardNavTree,
    ...userNavTree,
    ...contentNavTree,
    ...settingsTree
]

export default navigationConfig;
