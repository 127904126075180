import DefaultConfigurator, {ContentDetailsConfigurator} from "./base";
import {Card, Col, Row} from "antd";
import React from "react";
import { Typography } from 'antd';
import moment from 'moment';

const { Text, Title } = Typography;

export default class CaselawConfigurator extends DefaultConfigurator{
    updateColumns(columns){
        const citation = {
            title: 'Citation',
            dataIndex: 'citation',
            key: 'citation',
        }
        columns.splice(1, 0, citation);
        return columns;
    }

    createDetailsConfigurator(component){
        return new DetailsConfigurator(component);
    }
}

class DetailsConfigurator extends ContentDetailsConfigurator{
    getContentTitle(content) {
        const title = content.title.replace("\n", "<br/>")
        return <Title level={4}>
            <div dangerouslySetInnerHTML={{__html: title}}/>
        </Title>
    }
    getPostTitleComponent(content) {
        let date = "";
        if(content.judgement_date) {
            date = moment(content.judgement_date).format("YYYY-MM-DD")
            date = <Text><small>Judgement Date: {date}</small><br/></Text>
        }
        return <>{date} <Text>{content.citation}</Text> </>
    }
    renderDetailsContent(content){
        const tabList = [
            {
                key: 'case',
                tab: 'Case Summary',
            },
            {
                key: 'judgement',
                tab: 'Judgement Summary',
            },
        ];

        let activeKey = this.component.state.activeKey || 'case';

        const contentList = {
            'case': <div dangerouslySetInnerHTML={{__html: content.case_summary_html}}></div>,
            'judgement': <div dangerouslySetInnerHTML={{__html: content.judgement_html}}></div>,
        };

        const card = <Card tabList={tabList} onTabChange={e => this.tabWasChanged(e)}>
            {contentList[activeKey]}
        </Card>
        return card;
    }

    tabWasChanged(key){
        this.component.setState({ activeKey: key });
    }
}