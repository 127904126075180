import {authService} from 'auth/FetchInterceptor'
import {PATHS} from "../constants/services/auth";

const BackendAuthService = {}
BackendAuthService.login = function (data){
    return authService(PATHS.login, {
        method: 'POST',
        headers: {
            'public-request': 'true'
        },
        data: data
    }).then(resp => resp.result);
}

BackendAuthService.getUserProfile = function () {
    return authService(PATHS.userProfile, {
        method: 'POST'
    }).then(resp => resp.result)
}

export default BackendAuthService;