import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {loadApplicationUsers, applicationUsersLoaded,
    loadUserStatistics, statisticsLoaded,
    disableLoadingData, loadOrganizations, organizationsLoaded,
    saveOrganization, organizationSaved, organizationSaveFailure,
    loadedSubscriptionType, loadSubscriptionType} from "../slices/users"
import {showNetworkErrorMessage} from "../slices/theme";
import BackendUserService from "../../services/users";
import {LOADER_IDENTITY_ORGANIZATION_LIST, LOADER_IDENTITY_USER_LIST, LOADER_IDENTITY_SUBSCRIPTION_TYPES} from "../../constants/ThemeConstant";

// import utils from "../../utils";

export function* loadApplicationUserList() {
    yield takeEvery(loadApplicationUsers.type, function* ({payload}) {
        try {
            const data = payload || {};
            const load = yield call(BackendUserService.getUserList, data);
            yield put(applicationUsersLoaded(load))
        } catch (err) {
            yield put(disableLoadingData({identity: LOADER_IDENTITY_USER_LIST}))
            yield put(showNetworkErrorMessage(err));
        }
    });
}

export function* loadApplicationUserStatistics() {
    yield takeEvery(loadUserStatistics.type, function* ({payload}) {
        try {
            const load = yield call(BackendUserService.getUserStatistics);
            yield put(statisticsLoaded(load))
        } catch (err) {

        }
    });
}

export function* listOrganizations() {
    yield takeEvery(loadOrganizations.type, function* ({payload}) {
        try {
            const data = payload || {};
            const load = yield call(BackendUserService.listOrganization, data);
            yield put(organizationsLoaded(load))
        } catch (err) {
            yield put(disableLoadingData({identity: LOADER_IDENTITY_ORGANIZATION_LIST}))
            yield put(showNetworkErrorMessage(err));
        }
    });
}

export function* saveOrganizationInfo() {
    yield takeEvery(saveOrganization.type, function* ({payload}) {
        try {
            const data = payload || {};
            yield call(BackendUserService.saveOrganization, data);
            yield put(organizationSaved())
        } catch (err) {
            yield put(organizationSaveFailure());
            yield put(showNetworkErrorMessage(err));
        }
    });
}

export function* loadSubscriptionTypes() {
    yield takeEvery(loadSubscriptionType.type, function* ({payload}) {
        try {
            const data = payload || {};
            const result = yield call(BackendUserService.listSubscriptionTypes, data);
            yield put(loadedSubscriptionType(result))
        } catch (err) {
            yield put(disableLoadingData({identity: LOADER_IDENTITY_SUBSCRIPTION_TYPES}));
            yield put(showNetworkErrorMessage(err));
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(loadApplicationUserList),
        fork(loadApplicationUserStatistics),
        fork(listOrganizations),
        fork(saveOrganizationInfo),
        fork(loadSubscriptionTypes)
    ]);
}