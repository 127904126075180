import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import * as serviceWorker from './serviceWorker';

const render = () => {
    const App = require('App').default
    ReactDOM.render(<App />, document.getElementById('root'));
}
render();
if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('App', render)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
