import { all } from 'redux-saga/effects';
import Auth from './Auth';
import AppUsers from './users';
import ContentManagement from './content';

export default function* rootSaga(getState) {
  yield all([
    Auth(), AppUsers(), ContentManagement()
  ]);
}
