import React from "react";
import {Button, Card, Col, Form, Input, notification, Row, Select, Switch, Tag, Typography} from "antd";
import {ContentType} from "../../../../configs/ContentTypes";
import NewsEditForm from "../edit/news";
import Utils from "../../../../utils";
import CaselawEditForm from "../edit/caselaw";
import TaxAlertEditForm from "../edit/taxalert";
import CircularEditForm from "../edit/circular";
import TaxtreatyEditForm from "../edit/taxtreaty";
import moment from 'moment';
import Covid19EditForm from "../edit/covid19";
import EventEditForm from "../edit/event";
import LegislationEditForm from "../edit/legislation";
import SectionEditForm from "../edit/section";
import SubSectionEditForm from "../edit/subsection";
import BackendContentService from "../../../../services/content_services";
import {ContentGroupActionRequestType} from "../../../../configs/content_edit_form_item";
import {BulkListMenuKey} from "../bulk_menu_keys";
import ScheduleEditForm from "../edit/schedule";
import SubLegislationEditForm from "../edit/sublegislation";
import RichEditorInput from "../../../reuseable/rich_editor";
import ImageFieldWithUploaderInput from "../../../reuseable/image_field_withupload";

const { Title } = Typography;
const { Option } = Select;


export default class DefaultConfigurator{
    addDefaultToRequestData(data){

    }
    updateColumns(columns){

    }
    updateListMenu(menuList, state){

    }

    getEditLink(content, isFromDetails, currentUrl) {
        const path = currentUrl.split("/");
        if(isFromDetails){
            const id = path.pop();
            path.pop();
            path.push("edit");
            path.push(id);
            return path.join("/");
        }
        path.push("edit");
        path.push(content.id);
        return path.join("/");
    }
    /**
     *
     * @param component
     * @returns {ContentDetailsConfigurator}
     */
    getDetailsConfigurator(component){
        const config = this.createDetailsConfigurator(component);
        config.baseConfigurator = this;
        return config;
    }

    /**
     *
     * @param component
     * @returns {ContentDetailsConfigurator}
     */
    createDetailsConfigurator(component) {
        return new ContentDetailsConfigurator(component);
    }

    /**
     *
     * @param component
     * @returns {ContentFormEditorConfigurator}
     */
    getFormEditor(component){
        return new ContentFormEditorConfigurator(component);
    }

    /**
     *
     * @param currentTitle
     * @param content
     * @param component
     * @returns {*}
     */
    getDetailsTitle(currentTitle, content, component){
        return currentTitle
    }

    renderDetailsContent(renderedContent, content, component){
        return renderedContent;
    }

    /**
     *
     * @param menuKey
     * @param selectedItems
     * @param contentType
     * @param component
     * @param callback
     * @returns {boolean}
     */
    handleContentListMenuClick(menuKey, selectedItems, contentType, component, callback) {
        if(menuKey === BulkListMenuKey.publish || menuKey === BulkListMenuKey.unpublish) {
            let label = "";
            let action = null;
            if(menuKey === BulkListMenuKey.publish) {
                action = ContentGroupActionRequestType.publish;
                label = "Publishing";
            }
            else if(menuKey === BulkListMenuKey.unpublish) {
                action = ContentGroupActionRequestType.unPublish;
                label = "Un-publishing";
            }
            const requestData = {
                content_type: contentType.value,
                content_ids: selectedItems.map(i => i.id),
                action: action
            };
            this.processMenuBulkAction(label, requestData, contentType, component, callback);
            return true;
        }
        return false;
    }

    processMenuBulkAction(label, data, contentType, component, callback){
        const {showPageLoader, hidePageLoader, showNetworkErrorMessage} = component.props;
        showPageLoader(`${label} Item(s}`);
        BackendContentService.performContentAction(data).then((response) => {
            hidePageLoader();
            let notificationParam = {
                message: "Content Updated",
                description: "Your item(s) have been successfully updated"
            };
            notification.success(notificationParam);
            callback(contentType)

        }).catch(error => {
            hidePageLoader();
            showNetworkErrorMessage(error)
        })
    }
}

export class ContentDetailsConfigurator{
    component
    /**
     * @type {DefaultConfigurator}
     */
    baseConfigurator
    constructor(component) {
        this.component = component
    }
    getContentTitle(content) {
        return <Title level={4}>{content.title}</Title>
    }
    getContentCategories(content) {
        if(content.categories && content.categories.length > 0){
            const categories = content.categories.map((cate) => {
                return <Tag color="warning" key={cate.category_id}>{cate.category_label}</Tag>
            })
            return categories;
        }
        return <div/>
    }
    getPostTitleComponent(content){
        return null;
    }
    updateMenu(content, menuList){
        return menuList;
    }

    onDetailsMenuSelected(menuKey, selectedItems, contentType, component, callback){
        const configurator = this.baseConfigurator;
        return configurator.handleContentListMenuClick(menuKey, selectedItems, contentType, component, callback);
    }

    buildDetailsTitle(title, postTitle, categories, content){
        return <Row className={"content-title"} style={{textAlign: "center"}}>
            <Col span={24}>{title}</Col>
            {postTitle && <Col span={24}>{postTitle}</Col> }
            <Col span={24}>
                <div>{categories}</div>
            </Col>
        </Row>
    }
    renderDetailsContent(content){
        const synopsis = content.synopsis && <div className="synposis">{content.synopsis}</div>
        return <Card>
            {synopsis}
            <div dangerouslySetInnerHTML={{__html: content.content_html}}></div>
        </Card>
    }
}

export class ContentFormEditorConfigurator{
    /**
     *
     * @param contentType {ContentTypeData}
     * @param content
     * @param isNew {boolean}
     * @param saveCallback {function}
     */
    buildForm(contentType, content, isNew, saveCallback){
        const formReference = React.createRef();
        let data = [];
        switch (contentType){
            case ContentType.news:
                data.push(<NewsEditForm content={content} isNew={isNew} contentType={contentType} onSaveCallback={saveCallback} ref={formReference}/>)
                break;
            case ContentType.caseLaw:
                data.push(<CaselawEditForm content={content} isNew={isNew} contentType={contentType} onSaveCallback={saveCallback} ref={formReference}/>)
                break;
            case ContentType.taxAlert:
                data.push(<TaxAlertEditForm content={content} isNew={isNew} contentType={contentType} onSaveCallback={saveCallback} ref={formReference}/>)
                break;
            case ContentType.circular:
                data.push(<CircularEditForm content={content} isNew={isNew} contentType={contentType} onSaveCallback={saveCallback} ref={formReference}/>)
                break;
            case ContentType.taxTreaty:
                data.push(<TaxtreatyEditForm content={content} isNew={isNew} contentType={contentType} onSaveCallback={saveCallback} ref={formReference}/>)
                break;
            case ContentType.covid19:
                data.push(<Covid19EditForm content={content} isNew={isNew} contentType={contentType} onSaveCallback={saveCallback} ref={formReference}/>)
                break;
            case ContentType.event:
                data.push(<EventEditForm content={content} isNew={isNew} contentType={contentType} onSaveCallback={saveCallback} ref={formReference}/>)
                break;
            case ContentType.legislation:
                data.push(<LegislationEditForm content={content} isNew={isNew} contentType={contentType} onSaveCallback={saveCallback} ref={formReference}/>)
                break;
            case ContentType.section:
                data.push(<SectionEditForm content={content} isNew={isNew} contentType={contentType} onSaveCallback={saveCallback} ref={formReference}/>)
                break;
            case ContentType.subSection:
                data.push(<SubSectionEditForm content={content} isNew={isNew} contentType={contentType} onSaveCallback={saveCallback} ref={formReference}/>)
                break;
            case ContentType.schedule:
                data.push(<ScheduleEditForm content={content} isNew={isNew} contentType={contentType} onSaveCallback={saveCallback} ref={formReference}/>)
                break;
            case ContentType.subLegislation:
                data.push(<SubLegislationEditForm content={content} isNew={isNew} contentType={contentType} onSaveCallback={saveCallback} ref={formReference}/>)
                break;
            default:
                data.push(<div/>)
        }
        data.push(formReference)
        return data
    }

    /**
     *
     * @param contentType {ContentTypeData}
     */
    static getCategoriesSelectForContentType(contentType) {
        let categories = Utils.contentCategorization.getCategoriesForContentType(contentType.value);
        if (categories && categories.length > 0) {
            const options = categories.map((item) => {
                return <Option key={item.category_id} value={item.category_id}>{item.category_label}</Option>
            });
            return <Form.Item name={"content_categories"} label="Categories" rules={[
                {
                    required: true,
                    message: 'Please select a category',
                },
            ]}>
                <Select placeholder={"Select Categories"} mode="multiple">{options}</Select>
            </Form.Item>
        }
        return "";
    }

    static prepareDateField(fields, content){
        let props = {...content};
        fields.forEach(field => {
            if(props[field]) {
                props[`${field}_moment`] = moment(props[field]);
            }
        })
        return props;
    }

    static repopulateDateOnValues(fields, values) {
        let props = {...values};
        fields.forEach(field => {
            const dateField = `${field}_moment`;
            if(props[dateField]){
                props[field] = props[dateField].valueOf();
            }
            props[dateField] = null;
        });
        return props;
    }

    static moveOwnProperties(fields, propertyName, values){
        let props = {...values};
        let newData = {};
        fields.forEach(field => {
            newData[field] = props[field];
            props[field] = null;
        });
        props[propertyName] = newData;
        return props;
    }

    /**
     *
     * @param content
     * @param submitCallback
     * @param children
     * @param includeSynopsis
     * @param includeTitle
     * @param contentType {ContentTypeData}
     * @param includeImage {boolean}
     * @returns {JSX.Element}
     */
    static getEditForm(content, submitCallback, children, includeSynopsis, includeTitle, contentType, includeImage){
        let categories = ContentFormEditorConfigurator.getCategoriesSelectForContentType(contentType);
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 4,
                },
            },
        };
        const formContent = {...content};
        if(formContent.categories) {
            formContent.content_categories = formContent.categories.map((category) => category.category_id);
        }

        let formRef = React.createRef();
        let showSubmitButton = true
        if(contentType.value === ContentType.section.value || contentType.value === ContentType.subSection.value
        || contentType.value === ContentType.schedule.value || contentType.value === ContentType.subLegislation.value) {
            showSubmitButton = false;
        }
        const actions = this.getActionRow(contentType.value, content);
        let useImage = true;
        if(includeImage !== undefined){
            useImage = includeImage
        }
        const form = <Form
            className="content-edit-form"
            ref={formRef}
            {...formItemLayout}
            name="register"
            onFinish={e => submitCallback(e)}
            initialValues={formContent}
            scrollToFirstError
        >
            { includeTitle && <Form.Item
                name="title"
                label="Title"
                rules={[
                    {
                        required: true,
                        message: 'Please input your title!',
                    },
                ]}
            >
                <Input />
            </Form.Item> }
            {categories}
            {includeSynopsis && <Form.Item
                name="synopsis"
                label="Synopsis"
                rules={[
                ]}
            >
                <Input.TextArea />
            </Form.Item>}
            {actions}
            <Form.Item name="access_level" label="Content Access Level">
                <Select placeholder="Select access level for this content">
                    <Option value={0}>All Users</Option>
                    <Option value={1000}>Premium Subscribers</Option>
                </Select>
            </Form.Item>

            {useImage && this.getImageField()}


            {children}
            <Form.Item hidden={true} name="id"/>
            {showSubmitButton && <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>}
        </Form>
        return [form, formRef];
    }

    static getImageField() {
        return <Form.Item
            name="image"
            label="Content Image"
            rules={[
            ]}>
            <ImageFieldWithUploaderInput/>
        </Form.Item>
    }

    static getActionRow(contentType, content) {
        if(contentType === ContentType.section.value || contentType === ContentType.subSection.value
            || contentType === ContentType.schedule.value || contentType === ContentType.subLegislation.value){
            let dualWrappedCol = {
                xs: { span: 24 },
                sm: { span: 14 }
            };
            let dualWrappedLabel = {
                xs: { span: 24 },
                sm: { span: 10 }
            };
            if (content.id > 0) {
                return <Row>
                    <Col  offset={4} span={5}>
                    </Col>
                    <Col span={5}>
                        <Form.Item
                            labelCol={dualWrappedLabel}
                            wrapperCol={dualWrappedCol}
                            name="published"
                            label="Published"
                            valuePropName="checked"
                            rules={[
                            ]}
                        >
                            <Switch/>
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                    </Col>
                </Row>
            }
            else{
                return <Row>
                    <Col span={8} offset={4}>
                        <Form.Item
                            labelCol={dualWrappedLabel}
                            wrapperCol={dualWrappedCol}
                            name="published"
                            label="Published"
                            valuePropName="checked"
                            rules={[
                            ]}
                        >
                            <Switch/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            labelCol={dualWrappedLabel}
                            wrapperCol={dualWrappedCol}
                            name="isDraftMode"
                            label="Draft Mode"
                            valuePropName="checked"
                            rules={[
                            ]}
                        >
                            <Switch/>
                        </Form.Item>
                    </Col>
                </Row>
            }
        }
        else{
            let dualWrappedCol = {
                xs: { span: 24 },
                sm: { span: 14 }
            };
            let dualWrappedLabel = {
                xs: { span: 24 },
                sm: { span: 10 }
            };
            if (content.id > 0) {
                // don't allow edit of draft mode using the edit form
                return <Row>
                    <Col span={8} offset={4}>
                        <Form.Item
                            labelCol={dualWrappedLabel}
                            wrapperCol={dualWrappedCol}
                            name="published"
                            label="Published"
                            valuePropName="checked"
                            rules={[
                            ]}
                        >
                            <Switch/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            labelCol={dualWrappedLabel}
                            wrapperCol={dualWrappedCol}
                            name="is_featured"
                            label="Featured Content"
                            valuePropName="checked"
                            rules={[
                            ]}
                        >
                            <Switch/>
                        </Form.Item>
                    </Col>
                </Row>
            }
            else{
                return <Row>
                    <Col span={5} offset={4}>
                        <Form.Item
                            labelCol={dualWrappedLabel}
                            wrapperCol={dualWrappedCol}
                            name="published"
                            label="Published"
                            valuePropName="checked"
                            rules={[
                            ]}
                        >
                            <Switch/>
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        {contentType === ContentType.legislation.value && <Form.Item
                            labelCol={dualWrappedLabel}
                            wrapperCol={dualWrappedCol}
                            name="isDraftMode"
                            label="Draft Mode"
                            valuePropName="checked"
                            rules={[
                            ]}
                        >
                            <Switch/>
                        </Form.Item>}
                    </Col>
                    <Col span={5}>
                        <Form.Item
                            labelCol={dualWrappedLabel}
                            wrapperCol={dualWrappedCol}
                            name="is_featured"
                            label="Featured Content"
                            valuePropName="checked"
                            rules={[
                            ]}
                        >
                            <Switch/>
                        </Form.Item>
                    </Col>
                </Row>
            }
        }
    }
}