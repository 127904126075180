import store from 'store'
import lodash from 'lodash'
import expirePlugin from 'store/plugins/expire'
store.addPlugin(expirePlugin);

export class StorageNamespace{
    constructor(namespace) {
        this.namespace = namespace;
    }

    fullKey(name) {
        return `${this.namespace}::${name}`
    }

    setData(key, value, expire) {
        const realKey = this.fullKey(key);
        if(expire){
            store.set(realKey, value, expire);
        }
        else {
            store.set(realKey, value);
        }
    }

    getData(key) {
        const data = store.get(this.fullKey(key));
        if(data === undefined){
            return null;
        }
        return data;
    }

    remove(key){
        store.remove(this.fullKey(key));
    }

    removeAll() {
        store.each((val, key) => {
            if(lodash.startsWith(key, this.namespace)) {
                store.remove(key)
            }
        })
    }

}
class StorageUtils {
    static withNameSpace(prefix) {
        if(StorageUtils._namespaces[prefix]){
            return StorageUtils._namespaces[prefix];
        }
        else{
            const storage = new StorageNamespace(prefix);
            StorageUtils._namespaces[prefix] = storage;
            return storage;
        }
    }

    static defaultNamespace() {
        return StorageUtils.withNameSpace("default")
    }

    static setData(key, value, expire) {
        StorageUtils.defaultNamespace().setData(key, value, expire)
    }

    static getData(key) {
        return StorageUtils.defaultNamespace().getData(key)
    }

    static remove(key){
        StorageUtils.defaultNamespace().remove(key);
    }

    static removeAll(){
        StorageUtils.defaultNamespace().removeAll();
    }

    static clearAll(){
        store.clearAll();
    }

}
StorageUtils._namespaces = {};
export default StorageUtils;