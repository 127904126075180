export const ROW_GUTTER = 16;
export const SIDE_NAV_WIDTH = 250;
export const SIDE_NAV_COLLAPSED_WIDTH = 80;
export const SIDE_NAV_LIGHT = 'SIDE_NAV_LIGHT';
export const SIDE_NAV_DARK = 'SIDE_NAV_DARK';
export const NAV_TYPE_SIDE = 'SIDE'
export const NAV_TYPE_TOP = 'TOP'
export const DATA_TABLE_PAGE_SIZE = "dataTablePageSize";
export const DEFAULT_DATATABLE_PAGE_SIZE = 40;

export const LOADER_IDENTITY_USER_LIST = "userlist";
export const LOADER_IDENTITY_ORGANIZATION_LIST = "organizationlist";
export const LOADER_IDENTITY_SUBSCRIPTION_TYPES = "subscriptionTypes";