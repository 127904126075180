import {Enum} from "../utils/enums";

/**
 *
 * @type {{
 *     makeDraft: EnumSymbol,
 *     publishDraft: EnumSymbol,
 *     publish: EnumSymbol,
 *     unPublish: EnumSymbol
 * }}
 */
export const ContentGroupActionRequestType = new Enum({
    makeDraft: {value: "makeDraft"},
    publishDraft: {value: "publishDraft"},
    publish: {value: "publish"},
    unPublish: {value: "unpublish"},
})