import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {loadContent, contentLoaded, disableLoadingData, loadContentCategories, loadedContentCategories, saveContentCategoryType,
    saveContentCategory, savedContentCategory, savedContentCategoryType} from "../slices/content_slice";
import BackendContentService from "../../services/content_services";
import {showNetworkErrorMessage} from "../slices/theme";

export function* loadContentListList() {
    yield takeEvery(loadContent.type, function* ({payload}) {
        try {
            const data = payload || {};
            const contentType = data.contentType;
            const requestData = data.requestData;
            requestData['contentType'] = contentType;
            const response = yield call(BackendContentService.loadContent, requestData);
            yield put(contentLoaded({contentType, response}))
        } catch (err) {
            const data = payload || {};
            const contentType = data.contentType;
            yield put(disableLoadingData({contentType}))
            yield put(showNetworkErrorMessage(err));
        }
    });
}

export function* loadContentCategoryList() {
    yield takeEvery(loadContentCategories.type,function* ({payload}) {
        try {
            const response = yield call(BackendContentService.loadContentCategories, null);
            yield put(loadedContentCategories({data: response}))
        } catch (err) {
            yield put(loadedContentCategories({}))
            yield put(showNetworkErrorMessage(err));
        }
    });
}

export function* createCategoryType() {
    yield takeEvery(saveContentCategoryType.type,function* ({payload}) {
        try {
            const data = payload || {};
            const response = yield call(BackendContentService.createContentCategoryType, data);
            yield put(savedContentCategoryType({data: response}))
        } catch (err) {
            yield put(savedContentCategoryType({}))
            yield put(showNetworkErrorMessage(err));
        }
    });
}

export function* saveContentCategoryItem() {
    yield takeEvery(saveContentCategory.type,function* ({payload}) {
        try {
            const data = payload || {};
            const response = yield call(BackendContentService.saveContentCategory, data);
            yield put(savedContentCategory({data: response}))
        } catch (err) {
            yield put(savedContentCategory({}))
            yield put(showNetworkErrorMessage(err));
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(loadContentListList),
        fork(loadContentCategoryList),
        fork(createCategoryType),
        fork(saveContentCategoryItem)
    ]);
}