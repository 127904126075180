import {Enum} from "../utils/enums";
import {
    CaselawConfigurator,
    CircularConfigurator,
    LegislationConfigurator,
    SectionConfigurator,
    SubSectionConfigurator
} from "../views/app-views/content/configurator";
import DefaultConfigurator from "../views/app-views/content/configurator/base";
import TaxTreatyConfigurator from "../views/app-views/content/configurator/taxtreaty";
import Covid19Configurator from "../views/app-views/content/configurator/covid19";
import EventConfigurator from "../views/app-views/content/configurator/event";

function getContentTypePath(){
    return this.apiType.replaceAll("_", "-");
}

/**
 *
 * @type {{
 * news: ContentTypeData,
 * taxAlert: ContentTypeData,
 * circular: ContentTypeData,
 * event: ContentTypeData,
 * taxTreaty: ContentTypeData,
 * legislation: ContentTypeData,
 * caseLaw: ContentTypeData,
 * featuredContent: ContentTypeData,
 * schedule: ContentTypeData,
 * subLegislation: ContentTypeData,
 * subSection: ContentTypeData,
 * section: ContentTypeData,
 * covid19: ContentTypeData,
 * }}
 */
export const ContentType = new Enum({
    news: {value: "News", label: "News", apiType: "news", path: getContentTypePath, configurator: new DefaultConfigurator()},
    taxAlert: {value: "Taxalert", label: "Tax Alert", apiType: "taxalert", path: getContentTypePath, configurator: new DefaultConfigurator()},
    circular: {value: "Circular", label: "Circular", apiType: "circular", path: getContentTypePath, configurator: new CircularConfigurator()},
    event: {value: "Events", label: "Event", apiType: "tax_event", path: getContentTypePath, configurator: new EventConfigurator()},
    taxTreaty: {value: "TaxTreaty", label: "Tax Treaty", apiType: "double_tax_treaty", path: getContentTypePath, configurator: new TaxTreatyConfigurator()},
    legislation: {value: "Legislation", label: "Legislation", apiType: "legislation", path: getContentTypePath, configurator: new LegislationConfigurator()},
    caseLaw: {value: "Caselaw", label: "Case law", apiType: "caselaw", path: getContentTypePath, configurator: new CaselawConfigurator()},
    featuredContent: {value: "FeaturedContent", label: "Featured Content", apiType: "featured_content", path: getContentTypePath, configurator: new DefaultConfigurator()},
    schedule: {value: "Schedule", label: "Schedule", apiType: "schedule", path: getContentTypePath, configurator: new DefaultConfigurator()},
    subLegislation: {value: "SubLegislation", label: "Sub Legislation", apiType: "sublegislation", path: getContentTypePath, configurator: new DefaultConfigurator()},
    subSection: {value: "SubSection", label: "Sub Section", apiType: "sub_section", path: getContentTypePath, configurator: new SubSectionConfigurator()},
    section: {value: "Section", label: "Section", apiType: "act_section", path: getContentTypePath, configurator: new SectionConfigurator()},
    covid19: {value: "Covid19", label: "Covid-19", apiType: "covid_19", path: getContentTypePath, configurator: new Covid19Configurator()},
})

/**
 *
 * @returns {ContentTypeData[]}
 */
export function allContentTypes(){
    return [
        ContentType.news, ContentType.taxAlert,
        ContentType.circular, ContentType.event,
        ContentType.taxTreaty, ContentType.legislation,
        ContentType.caseLaw, ContentType.featuredContent,
        ContentType.schedule, ContentType.subLegislation,
        ContentType.subSection, ContentType.section,
        ContentType.covid19,
    ];
}

let typeMaps = null;
export function listContentTypes(){
    if (typeMaps == null){
        typeMaps = {};
        allContentTypes().forEach(item => {
            typeMaps[item.value] = item;
        })
    }
    return typeMaps;
}

/**
 *
 * @param type
 * @returns {ContentTypeData}
 */
export function getContentTypeForType(type) {
    const types = listContentTypes();
    return types[type];
}

/**
 *
 * @param path string
 * @returns {ContentTypeData}
 */
export const getContentTypeFromPath = (path) => {
    const apiType = path.replaceAll("-", "_");
    const allTypes = allContentTypes();
    for (let index in allTypes){
        const item = allTypes[index];
        if(item.apiType === apiType){
            return item;
        }
    }
    return null;
}

/**
 * @type {{value: string, label: string, apiType: string, path: function}}
 */
export const ContentTypeData = {}
