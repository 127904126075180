import {createSlice} from "@reduxjs/toolkit";
import {AUTH_TOKEN, USER_PROFILE, USER_PERMISSIONS} from "../../constants/services/auth";
import utils from "utils";

const initialState = {
    loading: false,
    message: '',
    showMessage: false,
    redirect: '',
    userPermissions: utils.storage.getData(USER_PERMISSIONS),
    token: utils.storage.getData(AUTH_TOKEN),
    profile: utils.storage.getData(USER_PROFILE)
}

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        authenticated: (state, action) => {
            state.loading = false;
            state.redirect = "/";
            state.token = action.payload.token;
            state.userPermissions = action.payload.user?.permissions || [];
            state.profile = action.payload.user;
        },
        showAuthMessage: (state, action) => {
            state.message = action.payload.message;
            state.showMessage = true;
            state.loading = false;
        },
        hideAuthMessage: (state, action) => {
            state.showMessage = false;
        },
        signOutSuccess: (state, action) => {
            state.token = null;
            state.redirect = "/";
            state.loading = false;
        },
        signUpSuccess: (state, action) => {
            state.loading = false;
            state.token = action.payload.token;
        },
        showLoading: (state, action) => {
            state.loading = true;
        },
        signInWithGoogleAuthenticated: (state, action) => {
            state.loading = false;
            state.token = action.payload.token;
        },
        signInWithFacebookAuthenticated: (state, action) => {
            state.loading = false;
            state.token = action.payload.token;
        },
        //saga actions
        signInUser: (state, action) => {},
        signOut: (state, action) => {},
        signUp: (state, action) => {},
        signInWithFacebook: (state, action) => {},
        signInWithFirebaseEmailPassword: (state, action) => {},
        signInWithGoogle: (state, action) => {}
    }
})

export const {authenticated, showAuthMessage, hideAuthMessage, signOutSuccess, signUpSuccess,
    showLoading, signInWithGoogleAuthenticated, signInWithFacebookAuthenticated,
    signInUser, signOut, signUp, signInWithGoogle, signInWithFacebook, signInWithFirebaseEmailPassword} = authSlice.actions;
export const reducer = authSlice.reducer;