import React from "react";
import {Card, Col, Menu, Row, Spin, Switch} from "antd";
import {connect} from "react-redux";
import lodash from "lodash";
import {hidePageLoader, showNetworkErrorMessage, showPageLoader} from "../../../../redux/slices/theme";
import BackendContentService from "../../../../services/content_services";
import LegislationSectionSubSectionTag from "./leg_sub_pill";
import {
    RadiusUprightOutlined,
    CheckCircleTwoTone,
    VerticalAlignTopOutlined,
    RollbackOutlined,
    EditOutlined,
    PlusCircleOutlined,
    UndoOutlined
} from "@ant-design/icons";
import PropTypes from "prop-types";
import {BulkListMenuKey} from "../bulk_menu_keys";

class LegislationSection extends React.Component{
    loadedSections = [];
    reloadingSections = false;
    constructor(props) {
        super(props);
        this.state = {
            loadingSections: true,
            showIndexes: false,
            allowMultiSelect: false,
            showTitles: false,
            showPublished: false,
            showDraft: false,
            showFilters: false,
            sections: [],
            allSections: [],
            selectedSections: []
        }
        this.loadSections();
    }
    reloadSections() {
        this.reloadingSections = true;
        this.loadedSections = []
        this.loadSections();
    }
    onMenuItemClicked(e){
        let key = e.key;
        const {selectedSections} = this.state;
        if(this.props.onSectionMenuItemClicked) {
            let sections = [];
            if(selectedSections){
                sections = selectedSections.map(sectionId => this.findSectionWithId(sectionId))
            }
            if(key === BulkListMenuKey.reindex){
                this.props.onSectionMenuItemClicked(key, this.state.allSections, this.state.allSections.length);
            }
            else{
                this.props.onSectionMenuItemClicked(key, sections, this.state.allSections.length);
            }
        }
    }
    onShowIndexChanged(e){
        this.setState({
            showIndexes: e
        })
    }
    onChangeMultiSelect(e){
        this.setState({
            allowMultiSelect: e
        });
    }
    onShowTitlesChanged(e){
        this.setState({
            showTitles: e
        });
    }
    onShowFiltersChanged(e){
        this.setState({
            showFilters: e
        });
    }
    onShowDraftChanged(e){
        this.setState({showDraft: e});
        setTimeout(() => {
            this.updateDisplayedItems();
        }, 500);
    }
    onShowPublishedChanged(e){
        this.setState({showPublished: e});
        setTimeout(() => {
            this.updateDisplayedItems();
        }, 500);
    }
    updateDisplayedItems() {
        const {showPublished, showDraft} = this.state;
        let items = this.state.allSections;
        if(showPublished && showDraft){
            items = lodash.filter(this.state.allSections.slice(), (item) => item.isDraftMode === true && item.published === true);
        }
        else{
            if(showPublished){
                items = lodash.filter(this.state.allSections.slice(), (item) => item.published === true);
            }
            else if(showDraft){
                items = lodash.filter(this.state.allSections.slice(), (item) => item.isDraftMode === true);
            }
            else if (!showPublished){
                items = this.state.allSections.slice();
                // items = lodash.filter(this.state.allSections.slice(), (item) => item.published === false);
            }
            else if(!showDraft){
                items = lodash.filter(this.state.allSections.slice(), (item) => item.isDraftMode === false);
            }
        }
        this.setState({sections: items});
    }
    findSectionWithId(id){
        return lodash.find(this.state.sections, (item) => item.id === id);
    }
    buildMenu() {
        let menuList = [];
        const {selectedSections} = this.state;
        menuList.push(
            <Menu.Item key="main" selectable={false} disabled>
                Sections
            </Menu.Item>
        )
        menuList.push(
            <Menu.Item key={BulkListMenuKey.add} icon={<PlusCircleOutlined />}> Add</Menu.Item>
        )

        if(selectedSections.length > 0){
            let hasDraftItem = lodash.findIndex(selectedSections, (id) => {
                const item = this.findSectionWithId(id);
                return item && item.isDraftMode === true;
            }) >= 0;
            let hasUndraftItem = lodash.findIndex(selectedSections, (id) => {
                const item = this.findSectionWithId(id);
                return item && item.isDraftMode === false;
            }) >= 0;
            let hasPublishedItem = lodash.findIndex(selectedSections, (id) => {
                const item = this.findSectionWithId(id);
                return item && item.published === true;
            }) >= 0;
            let hasUnpublishedItems = lodash.findIndex(selectedSections, (id) => {
                const item = this.findSectionWithId(id);
                return item && item.published === false;
            }) >= 0;
            if(selectedSections.length === 1){
                menuList.push(
                    <Menu.Item key={BulkListMenuKey.edit} icon={<EditOutlined />}>
                        Edit
                    </Menu.Item>
                )
            }
            if(hasDraftItem){
                menuList.push(
                    <Menu.Item key={BulkListMenuKey.publishDraft} icon={<CheckCircleTwoTone />}>
                        Publish Draft
                    </Menu.Item>
                )
            }
            if(hasUndraftItem){
                menuList.push(
                    <Menu.Item key={BulkListMenuKey.makeDraft} icon={<RadiusUprightOutlined />}>
                        Make Draft
                    </Menu.Item>
                )

            }
            if(hasPublishedItem){
                menuList.push(
                    <Menu.Item key={BulkListMenuKey.unpublish} icon={<RollbackOutlined />}>
                        Un-publish
                    </Menu.Item>
                )

            }
            if(hasUnpublishedItems){
                menuList.push(
                    <Menu.Item key={BulkListMenuKey.publish} icon={<VerticalAlignTopOutlined />}>
                        Publish
                    </Menu.Item>
                )

            }
        }

        menuList.push(
            <Menu.Item key={BulkListMenuKey.reindex} icon={<UndoOutlined />}>
                Re-Index
            </Menu.Item>
        );
        const menu = <Menu selectedKeys={[]} className="content-menu" onClick={e => this.onMenuItemClicked(e)} mode="horizontal">
            {menuList}
        </Menu>
        return <Row>
            <Col span={20}>{menu}</Col>
            <Col span={4} style={{textAlign: "right"}}>
                <Switch
                    checked={this.state.showFilters}
                    onChange={e => this.onShowFiltersChanged(e)}
                    checkedChildren="Filters"
                    unCheckedChildren="Filters"
                />
            </Col>
        </Row>
    }

    loadSections(param) {
        const {legislation} = this.props;
        const params = param || {};
        if(!params.size){
            params.size = 100;
        }
        if(!params.page){
            params.page = 0;
        }
        BackendContentService.loadLegislationSections(legislation.id, params).then((response) => {
            const data = response.result.data;
            const nextPage = response.next_page;
            if(data && data.length > 0){
                data.forEach(item => {
                    this.loadedSections.push(item);
                })
            }
            if(nextPage){
                const param = {page: nextPage};
                this.loadSections(param);
            }
            else{
                this.setState({
                    loadingSections: false,
                    sections: this.loadedSections.slice(),
                    allSections: this.loadedSections.slice(),
                    selectedSections: []
                });
                this.afterReload();
            }

        }).catch(error => {
            showNetworkErrorMessage(error)
            this.setState({
                loadingSections: false,
            });
        });
    }
    afterReload() {
        if(this.reloadingSections) {
            this.reloadingSections = false;
            this.setState({selectedSections: []});
            this.notifySelectionChanged();
        }
    }
    getLabelForSection(section){
        if(this.state.showTitles){
            return `${section.section_label} - ${section.title}`;
        }
        else if(this.sectionIsActive(section)){
            return `${section.section_label} - ${section.title}`;
        }
        return section.section_label;
    }
    getSectionIndex(section){
        return section.section_sort;
    }
    getSelectedSections() {
        return lodash.filter(this.state.sections, (section) => {
           return lodash.indexOf(this.state.selectedSections, section.id) >= 0;
        });
    }
    didSelectSection(section){
        let copy = this.state.selectedSections.slice();
        const index = lodash.indexOf(copy, section.id);
        if(index >= 0){
            lodash.remove(copy, (item)=>item === section.id)
        }
        else{
            if(this.state.allowMultiSelect){
                copy.push(section.id);
            }
            else{
                copy = [section.id];
            }
        }
        this.setState({
            selectedSections: copy
        })
        this.notifySelectionChanged();
    }
    notifySelectionChanged(){
        setTimeout(() => {
            if(this.props.didSelectSection){
                this.props.didSelectSection(this.getSelectedSections().slice());
            }
        }, 500);
    }
    sectionIsActive(section){
        const index = lodash.indexOf(this.state.selectedSections, section.id);
        return index >= 0;
    }
    getSectionItems() {
        return this.state.sections.map((item) => {
            const isActive = this.sectionIsActive(item);
            return <LegislationSectionSubSectionTag data={item}
                                                    showIndexes={this.state.showIndexes}
                                                    isDraft={item.isDraftMode}
                                                    isPublished={item.published}
                                                    indexCallback={e => this.getSectionIndex(e)}
                                                    key={item.id} labelCallback={e => this.getLabelForSection(e)}
                                                    didSelect={e => this.didSelectSection(e)} isActive={isActive}/>
        });
    }
    render() {
        const items = this.getSectionItems();
        const menu = this.buildMenu()
        const title = <div>
            <div className={"section-menu"}>{menu}</div>
        </div>
        const actions = this.getCardActions();
        const contentList = [];
        if(this.state.showFilters){
            contentList.push(<Col span={3} className="section-actions">{actions}</Col>)
            contentList.push(<Col span={21} className="section-content"><div className="section-content-inner">{items}</div></Col>)
        }
        else{
            contentList.push(<Col span={24} className="section-content"><div className="section-content-inner">{items}</div></Col>)
        }

        return <Spin tip={"Loading sections..."} spinning={this.state.loadingSections}>
            <Card title={title} className="section-wrapper-inner">
                <div className={"section-listing"}>
                    <Row>
                        {contentList}
                    </Row>
                </div>
            </Card>
        </Spin>
    }

    getCardActions() {
        const actions = [];
        actions.push(
            <Menu.Item key="index-switch">
                <Switch
                    checked={this.state.showIndexes}
                    onChange={e => this.onShowIndexChanged(e)}
                    checkedChildren="Hide Indexes"
                    unCheckedChildren="Show Indexes"
                />
            </Menu.Item>
        )
        actions.push(
            <Menu.Item key="select-switch">
                <Switch
                    checked={this.state.allowMultiSelect}
                    onChange={e => this.onChangeMultiSelect(e)}
                    checkedChildren="Multi-Select"
                    unCheckedChildren="Multi-Select"
                />
            </Menu.Item>
        )
        actions.push(
            <Menu.Item key="title-switch">
                <Switch
                    checked={this.state.showTitles}
                    onChange={e => this.onShowTitlesChanged(e)}
                    checkedChildren="Hide Titles"
                    unCheckedChildren="Show Titles"
                />
            </Menu.Item>
        )
        actions.push(
            <Menu.Item key="published-switch">
                <Switch
                    checked={this.state.showPublished}
                    onChange={e => this.onShowPublishedChanged(e)}
                    checkedChildren="Published"
                    unCheckedChildren="Published"
                />
            </Menu.Item>
        )
        actions.push(
            <Menu.Item key="draft-switch">
                <Switch
                    checked={this.state.showDraft}
                    onChange={e => this.onShowDraftChanged(e)}
                    checkedChildren="Draft"
                    unCheckedChildren="Draft"
                />
            </Menu.Item>
        )
        return <Menu selectedKeys={[]} className="content-menu" onClick={e => this.onMenuItemClicked(e)} mode="vertical">
            {actions}
        </Menu>
        // return actions;
    }
}

LegislationSection.propTypes = {
    legislation: PropTypes.any,
    didSelectSection: PropTypes.func,
    onSectionMenuItemClicked: PropTypes.func
}

const mapStateToProps = ({ contentManagement }) => {
    return {contentManagement};
}

const mapDispatchToProps = {
    showPageLoader, hidePageLoader, showNetworkErrorMessage
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(LegislationSection);