import { combineReducers } from '@reduxjs/toolkit'
import {reducer as AuthReducer} from "./slices/auth";
import {reducer as ThemeReducer} from "./slices/theme";
import {reducer as AppUserManagementReducer} from "./slices/users";
import {reducer as ContentManagement} from "./slices/content_slice"
const rootReducer = combineReducers({
    auth: AuthReducer,
    theme: ThemeReducer,
    userManagement: AppUserManagementReducer,
    contentManagement: ContentManagement,
})

// export type RootState = ReturnType<typeof rootReducer>

export default rootReducer