import lodash from 'lodash';
import utils from "./index";
import {DATA_TABLE_PAGE_SIZE, DEFAULT_DATATABLE_PAGE_SIZE} from "../constants/ThemeConstant";
class DataTableUtils {
    static buildDatatableRequestData(value) {
        console.log(value);
        const data = {};
        if(value.sorter && value.sorter.field) {
            data['sortProperty'] = value.sorter.field;
            data['sortDirection'] = 'asc';
            if(value.sorter.order === 'descend') {
                data['sortDirection'] = 'desc';
            }
        }
        if(value.pagination){
            data['pageSize'] = value.pagination.pageSize || DataTableUtils.getPaginationPageSize()
            data['page'] = value.pagination.current || 1;
        }
        else{
            data['pageSize'] = DataTableUtils.getPaginationPageSize()
        }
        if(value.filters) {
            const filters = value.filters;
            lodash.keys(filters).forEach(key => {
                const val = filters[key];
                if(!val) {
                    lodash.unset(filters, key);
                }
            })
            data['filters'] = filters;
        }
        data['search'] = value.search;

        return data
    }

    static defaultPagination() {
        return {
            pageSize: DataTableUtils.getPaginationPageSize(),
            hideOnSinglePage: true
        }
    }

    static  getPaginationPageSize(){
        return utils.storage.getData(DATA_TABLE_PAGE_SIZE) || DEFAULT_DATATABLE_PAGE_SIZE
    }

    static onShowSizeChange(current, size) {
        utils.storage.setData(DATA_TABLE_PAGE_SIZE, size);
    }

    static mergePageinationProp(pagination) {
        return {
            ...pagination,
            onShowSizeChange: DataTableUtils.onShowSizeChange,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
        }
    }
}

export default DataTableUtils;