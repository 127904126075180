import {createSlice} from "@reduxjs/toolkit";
import utils from "utils";
import {
    LOADER_IDENTITY_ORGANIZATION_LIST,
    LOADER_IDENTITY_SUBSCRIPTION_TYPES,
    LOADER_IDENTITY_USER_LIST
} from "../../constants/ThemeConstant";
const initialState = {
    appUsers: {
        loadingData: false,
        contentList: [],//[{firstname: "Kem", "lastname": "Peter", user_id:"9", isEnabled: true}, {firstname: "Ade", "lastname": "Lat", user_id:"10", isEnabled: true}],
        paginate: {
            ...utils.datatable.defaultPagination()
        },
        statistics:{}
    },
    organizationData: {
        loadingData: false,
        savingOrganization: false,
        contentList: [],
        paginate: {
            ...utils.datatable.defaultPagination()
        }
    },
    subscriptionType: {
        loadingData: false,
        contentList: [],
        paginate: {
            pageSize: 50
        },
    }
}

const updateStateFromDatatablePayload = (key, state, payload) => {
    state[key].paginate.current = payload.paginate.page;
    state[key].paginate.pageSize = payload.paginate.pageSize;
    state[key].paginate.total = payload.paginate.total;
    state[key].contentList = payload.contentList;
    state[key].loadingData = false
}

const slice = createSlice({
    name: 'users',
    initialState: initialState,
    reducers: {
        applicationUsersLoaded: (state, action) => {
            updateStateFromDatatablePayload('appUsers', state, action.payload)
        },
        statisticsLoaded: (state, action) => {
          state.appUsers.statistics = action.payload;
        },
        // net request
        loadApplicationUsers: (state, action) => {
            state.appUsers.loadingData = true
        },
        loadUserStatistics: (state, action) => {},

        organizationsLoaded: (state, action) => {
            updateStateFromDatatablePayload('organizationData', state, action.payload)
        },
        saveOrganization: (state, action) => {
            state.organizationData.savingOrganization = true
            state.organizationData.organizationSaved = null;
        },
        organizationSaved: (state, action) => {
            state.organizationData.savingOrganization = false
            state.organizationData.organizationSaved = true;
        },
        organizationSaveFailure: (state, action) => {
            state.organizationData.savingOrganization = false
            state.organizationData.organizationSaved = false;
        },
        loadOrganizations: (state, action) => {
            state.organizationData.loadingData = true
        },
        loadSubscriptionType: (state, action) => {
            state.subscriptionType.loadingData = true;
        },
        loadedSubscriptionType: (state, action) => {
            updateStateFromDatatablePayload('subscriptionType', state, action.payload)
        },
        disableLoadingData: (state, action) => {
            if(action.payload.identity === LOADER_IDENTITY_USER_LIST){
                state.appUsers.loadingData = false
            }
            if(action.payload.identity === LOADER_IDENTITY_ORGANIZATION_LIST) {
                state.organizationData.loadingData = false
            }
            else if(action.payload.identity === LOADER_IDENTITY_SUBSCRIPTION_TYPES){
                state.subscriptionType.loadingData = false
            }
        }
    }
})

export const {loadApplicationUsers, applicationUsersLoaded, disableLoadingData,
    loadUserStatistics, statisticsLoaded, loadOrganizations, organizationsLoaded,
    saveOrganization, organizationSaved, organizationSaveFailure,
    loadedSubscriptionType, loadSubscriptionType} = slice.actions
export const reducer = slice.reducer;