import {authService} from 'auth/FetchInterceptor'
import {PATHS} from "../constants/services/auth";

const BackendUserService = {}
BackendUserService.getUserList = function (data){
    return authService(PATHS.tax247UserList, {
        method: 'POST',
        headers: {
        },
        data: data
    }).then(resp => resp.result);
}

BackendUserService.getUserStatistics = function (){
    return authService(PATHS.tax247UserStats, {
        method: 'POST',
        headers: {
        }
    }).then(resp => resp.result);
}

BackendUserService.listOrganization = function (data){
    return authService(PATHS.tax247OrganizationList, {
        method: 'POST',
        headers: {
        },
        data: data
    }).then(resp => resp.result);
}

BackendUserService.saveOrganization = function (data){
    return authService(PATHS.saveOrganization, {
        method: 'POST',
        headers: {
        },
        data: data
    }).then(resp => resp.result);
}

BackendUserService.listSubscriptionTypes = function (data){
    return authService(PATHS.subscriptionTypeList, {
        method: 'POST',
        headers: {
        },
        data: data
    }).then(resp => resp.result);
}

BackendUserService.assignSubscription = function (data){
    return authService(PATHS.assignSubscription, {
        method: 'POST',
        headers: {
        },
        data: data
    });
}

BackendUserService.moveUsersToOrganization = function (data) {
    return authService(PATHS.moveUserToOrganization, {
        method: 'POST',
        headers: {
        },
        data: data
    });
}

BackendUserService.saveUsers = function (data){
    return authService(PATHS.tax247UserSave, {
        method: 'POST',
        headers: {
        },
        data: data
    });
}
BackendUserService.bulkUploadUsers = function (file, onUploadProgress){
    let formData = new FormData();

    formData.append("file", file);

    return authService.post(PATHS.tax247UserBulkUpload, formData, {
        // method: 'POST',
        headers: {
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress
    });
}

export default BackendUserService;