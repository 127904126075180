import React from "react";
import PropTypes from "prop-types";
import {Badge, Tag} from "antd";

class LegislationSectionSubSectionTag extends React.Component{
    onTagClicked(e){
        const {data, didSelect} = this.props;
        if(didSelect){
            didSelect(data);
        }
    }
    render() {
        const {isActive, labelCallback, data, showIndexes, indexCallback, isDraft, isPublished} = this.props;
        let classes = ""
        if(isActive){
            classes += " is-active"
        }
        if(isDraft) {
            classes += " is-draft"
        }
        if(!isPublished) {
            classes += " is-unpublished"
        }
        let dataTag = <Tag className={"tagging"}>{labelCallback(data)}</Tag>
        if(showIndexes){
            const index = indexCallback(data);
            dataTag = <Badge count={index} showZero overflowCount={10000}>{dataTag}</Badge>
        }
        return <div className={`leg-sec-subsec ${classes}`} onClick={e => this.onTagClicked(e)}>{dataTag}</div>
    }
}

LegislationSectionSubSectionTag.propTypes = {
    data: PropTypes.any,
    labelCallback: PropTypes.func,
    indexCallback: PropTypes.func,
    isActive: PropTypes.bool,
    didSelect: PropTypes.func,
    showIndexes: PropTypes.any,
    isDraft: PropTypes.bool,
    isPublished: PropTypes.bool
}

export default LegislationSectionSubSectionTag