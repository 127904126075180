import React from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {DatePicker, Form, Input} from "antd";
import {ContentFormEditorConfigurator} from "../configurator/base";
import {ContentType} from "../../../../configs/ContentTypes";

class LegislationEditForm extends React.Component{

    dateFields = ['commencement_date'];
    ownFields = ["commencement_date", "acronym", "mode_of_compliance", "law_enforcer", "penalties", "deadlines"];
    onFinish(values){
        const {onSaveCallback} = this.props;
        let valuesToSave = ContentFormEditorConfigurator.repopulateDateOnValues(this.dateFields, values);
        valuesToSave = ContentFormEditorConfigurator.moveOwnProperties(this.ownFields, "legislation", valuesToSave);
        if(onSaveCallback){
            valuesToSave['content_type'] = ContentType.legislation.value;
            onSaveCallback(valuesToSave);
        }
    }

    getForm() {
        return this.formReference;
    }

    render() {
        const {content} = this.props;
        const formContent = ContentFormEditorConfigurator.prepareDateField(this.dateFields, content)
        const children = <div>
            <Form.Item
                name="acronym"
                label="Acronym"
                rules={[{
                    required: true,
                    message: 'Please specify an acronym!',
                }]}>
                <Input />
            </Form.Item>
            <Form.Item
                name="mode_of_compliance"
                label="Compliance Mode"
                rules={[]}>
                <Input />
            </Form.Item>
            <Form.Item
                name="law_enforcer"
                label="Law Enforcer"
                rules={[]}>
                <Input />
            </Form.Item>
            <Form.Item label="Commencement Date" name="commencement_date_moment">
                <DatePicker />
            </Form.Item>
        </div>
        const val = ContentFormEditorConfigurator.getEditForm(formContent, this.onFinish.bind(this), children, true, true, ContentType.legislation)
        const form = val[0];
        this.formReference = val[1];
        return form;
    }
}

const mapStateToProps = ({ contentManagement }) => {
    return {contentManagement};
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(LegislationEditForm);