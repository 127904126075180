import React from "react";
import {Button, Card, Col, Menu, notification, Row, Spin, Switch} from "antd";
import {connect} from "react-redux";
import {hidePageLoader, showNetworkErrorMessage, showPageLoader} from "../../../../redux/slices/theme";
import PropTypes from "prop-types";
import LegislationSectionSubSectionTag from "./leg_sub_pill";
import BackendContentService from "../../../../services/content_services";
import lodash from "lodash";
import {
    CheckCircleTwoTone,
    EditOutlined,
    RadiusUprightOutlined,
    RollbackOutlined,
    VerticalAlignTopOutlined,
    PlusCircleOutlined, UndoOutlined
} from "@ant-design/icons";
import {BulkListMenuKey} from "../bulk_menu_keys";
import Text from "antd/es/typography/Text";
import LegislationReferenceDialog from "./reference_modal";
import {ContentType} from "../../../../configs/ContentTypes";
import LegislationReferenceList from "./reference-list";

class LegislationSubSection extends React.Component{

    loadedSubSections = [];
    loadedSectionIdd;
    reloadingSubSections = false;
    hasLoadedFirst = false

    constructor(props) {
        super(props);
        this.state = {
            loadingSubSections: true,
            subsections: [],
            selectedSubSections: [],
            showIndexes: false,
            allowMultiSelect: false,
            showDraft: false,
            showPublished: false,
            showFilters: false,
            allSubsections: []
        }
    }

    onMenuItemClicked(e){
        let key = e.key;
        const {selectedSubSections} = this.state;
        if(this.props.onSubSectionMenuItemClicked) {
            let subSections = [];
            if(selectedSubSections) {
                subSections = selectedSubSections.map(item => this.findSubSectionWithId(item));
            }
            if(key === BulkListMenuKey.reindex){
                this.props.onSubSectionMenuItemClicked(key, this.state.allSubsections, this.state.allSubsections.length);
            }
            else{
                this.props.onSubSectionMenuItemClicked(key, subSections, this.state.allSubsections.length);
            }
        }
    }

    reloadCurrentSubSections() {
        if(this.loadedSectionId){
            this.reloadingSubSections = true;
            this.loadedSubSections = [];
            this.loadSubsections(this.loadedSectionId, {});
        }
    }

    afterReload() {
        if(this.reloadingSubSections) {
            this.reloadingSubSections = false;
            this.setState({selectedSubSections: []});
        }
    }

    loadSubsections(sectionId, param){
        this.setState({
            loadingSubSections: true,
        });
        const params = {size: 50, page: 0, ...param};
        BackendContentService.loadLegislationSubSections(sectionId, params).then((response) => {
            const data = response.result.data;
            const nextPage = response.next_page;
            if(data && data.length > 0){
                data.forEach(item => {
                    this.loadedSubSections.push(item);
                })
            }
            if(nextPage && nextPage > 0){
                const param = {page: nextPage};
                this.loadSubsections(sectionId, param);
            }
            else{
                this.setState({
                    loadingSubSections: false,
                    subsections: this.loadedSubSections.slice(),
                    allSubsections: this.loadedSubSections.slice(),
                    selectedSubSections: []
                });
            }
            // this.loadedSectionId = sectionId;

        }).catch(error => {
            showNetworkErrorMessage(error)
            this.setState({
                loadingSubSections: false,
            });
        });
    }

    onShowIndexChanged(e){
        this.setState({
            showIndexes: e
        })
    }

    onChangeMultiSelect(e){
        this.setState({
            allowMultiSelect: e
        });
    }

    onShowDraftChanged(e){
        this.setState({showDraft: e});
        setTimeout(() => {
            this.updateDisplayedItems();
        }, 500);
    }

    onShowPublishedChanged(e){
        this.setState({showPublished: e});
        setTimeout(() => {
            this.updateDisplayedItems();
        }, 500);
    }

    onShowFiltersChanged(e){
        this.setState({
            showFilters: e
        });
    }

    updateDisplayedItems() {
        const {showPublished, showDraft} = this.state;
        let items = this.state.allSubsections;
        if(showPublished && showDraft){
            items = lodash.filter(this.state.allSubsections.slice(), (item) => item.isDraftMode === true && item.published === true);
        }
        else{
            if(showPublished){
                items = lodash.filter(this.state.allSubsections.slice(), (item) => item.published === true);
            }
            else if(showDraft){
                items = lodash.filter(this.state.allSubsections.slice(), (item) => item.isDraftMode === true);
            }
            else if (!showPublished){
                items = this.state.allSubsections.slice();
                // items = lodash.filter(this.state.allSubsections.slice(), (item) => item.published === false);
            }
            else if(!showDraft){
                items = lodash.filter(this.state.allSubsections.slice(), (item) => item.isDraftMode === false);
            }
        }
        this.setState({subsections: items});
    }

    getSelectedSubSections() {
        return lodash.filter(this.state.subsections, (item) => {
            return lodash.indexOf(this.state.selectedSubSections, item.id) >= 0;
        });
    }

    findSubSectionWithId(id){
        return lodash.find(this.state.subsections, (item) => item.id === id);
    }

    buildMenu() {

        let menuList = [];
        const {selectedSubSections} = this.state;
        const {section} = this.props;
        menuList.push(
            <Menu.Item key="main" selectable={false} disabled>
                Sub Sections
            </Menu.Item>
        );
        if(section) {
            menuList.push(
                <Menu.Item key={BulkListMenuKey.add} icon={<PlusCircleOutlined />}> Add</Menu.Item>
            )
            if(this.state.allSubsections.length > 0){
                if(selectedSubSections.length > 0){
                    let hasDraftItem = lodash.findIndex(selectedSubSections, (id) => {
                        const item = this.findSubSectionWithId(id);
                        return item && item.isDraftMode === true;
                    }) >= 0;
                    let hasUndraftItem = lodash.findIndex(selectedSubSections, (id) => {
                        const item = this.findSubSectionWithId(id);
                        return item && item.isDraftMode === false;
                    }) >= 0;
                    let hasPublishedItem = lodash.findIndex(selectedSubSections, (id) => {
                        const item = this.findSubSectionWithId(id);
                        return item && item.published === true;
                    }) >= 0;
                    let hasUnpublishedItems = lodash.findIndex(selectedSubSections, (id) => {
                        const item = this.findSubSectionWithId(id);
                        return item && item.published === false;
                    }) >= 0;
                    if(selectedSubSections.length === 1){
                        menuList.push(
                            <Menu.Item key={BulkListMenuKey.edit} icon={<EditOutlined />}>
                                Edit
                            </Menu.Item>
                        )
                    }
                    if(hasDraftItem){
                        menuList.push(
                            <Menu.Item key={BulkListMenuKey.publishDraft} icon={<CheckCircleTwoTone />}>
                                Publish Draft
                            </Menu.Item>
                        )
                    }
                    if(hasUndraftItem){
                        menuList.push(
                            <Menu.Item key={BulkListMenuKey.makeDraft} icon={<RadiusUprightOutlined />}>
                                Make Draft
                            </Menu.Item>
                        )

                    }
                    if(hasPublishedItem){
                        menuList.push(
                            <Menu.Item key={BulkListMenuKey.unpublish} icon={<RollbackOutlined />}>
                                Un-publish
                            </Menu.Item>
                        )

                    }
                    if(hasUnpublishedItems){
                        menuList.push(
                            <Menu.Item key={BulkListMenuKey.publish} icon={<VerticalAlignTopOutlined />}>
                                Publish
                            </Menu.Item>
                        )

                    }
                }
                menuList.push(
                    <Menu.Item key={BulkListMenuKey.reindex} icon={<UndoOutlined />}>
                        Re-Index
                    </Menu.Item>
                );

            }
        }

        const menu = <Menu selectedKeys={[]} className="content-menu" onClick={e => this.onMenuItemClicked(e)} mode="horizontal">
            {menuList}
        </Menu>
        return <Row>
            <Col span={20}>{menu}</Col>
            <Col span={4} style={{textAlign: "right"}}>
                <Switch
                    checked={this.state.showFilters}
                    onChange={e => this.onShowFiltersChanged(e)}
                    checkedChildren="Filters"
                    unCheckedChildren="Filters"
                />
            </Col>
        </Row>
    }

    subSectionIsActive(item){
        const index = lodash.indexOf(this.state.selectedSubSections, item.id);
        return index >= 0;
    }

    getSubSectionIndex(item){
        return item.sub_section_sort
    }

    getLabelForSubSection(item){
        return item.sub_section_number_string;
    }

    didSelectSubSection(itemData){
        let copy = this.state.selectedSubSections.slice();
        const index = lodash.indexOf(copy, itemData.id);
        if(index >= 0){
            lodash.remove(copy, (item)=>item === itemData.id)
        }
        else{
            if(this.state.allowMultiSelect){
                copy.push(itemData.id);
            }
            else{
                copy = [itemData.id];
            }
        }
        this.setState({
            selectedSubSections: copy
        });
    }

    getSelectedSubSectionInfo() {
        const selected = this.getSelectedSubSections();
        if(selected && selected.length === 1){
            const subSection = selected[0];
            let content = subSection.content;
            if(content){
                content = content.replace(/(?:\r\n|\r|\n)/g, '<br/>');
            }
            return <div className="sub-section-info">
                <hr/>
                <div dangerouslySetInnerHTML={{__html: content}}/>
            </div>
        }
        return <div/>
    }

    renderContentWithSection(){
        const listing =  this.state.subsections.map((item) => {
            const isActive = this.subSectionIsActive(item);
            return <LegislationSectionSubSectionTag data={item}
                                                    showIndexes={this.state.showIndexes}
                                                    isDraft={item.isDraftMode}
                                                    isPublished={item.published}
                                                    indexCallback={e => this.getSubSectionIndex(e)}
                                                    key={item.id} labelCallback={e => this.getLabelForSubSection(e)}
                                                    didSelect={e => this.didSelectSubSection(e)} isActive={isActive}/>
        });

        const details = this.getSelectedSubSectionInfo();
        const references = this.getReferencesRender();
        return <div>
            <div className={"sub-section-content-inner"}>{listing}</div>
            {details}
            {references}
        </div>
    }

    onAddReference() {
        let subSection = this.getSelectedSubSections()
        this.setState({
            showReferenceForm: true,
            referencedItem: subSection[0]
        })
    }

    getReferencesRender() {
        const selected = this.getSelectedSubSections();
        let references = <div className="no-reference">No Reference</div>
        if(selected && selected.length === 1) {
            const subSection = selected[0];
            if(subSection.references && subSection.references.length > 0){
                references = <LegislationReferenceList references={subSection.references}
                didRequestReferenceDelete={item => this.onReferenceDeleteRequested(item)}
                                                       didRequestEdit={item => this.onReferenceEditRequested(item)}/>
            }
            return <div className="references-holder">
                <h4>References</h4>
                <hr/>
                <div>{references}</div>
                <div className="text-right"><Button className="add-reference" onClick={e => this.onAddReference()}>Add Reference</Button></div>
            </div>
        }
        return "";
    }

    onReferenceDeleteRequested(item){
        let subSection = this.getSelectedSubSections();
        if(this.props.onDeleteReferenceRequested) {
            this.props.onDeleteReferenceRequested(subSection[0], item, ContentType.subSection.value, () => {
                this.reloadCurrentSubSections();
                this.setState({
                    showReferenceForm: false,
                    referencedItem: null,
                    reference: null
                });
            });
        }
    }

    onReferenceEditRequested(item){
        let subSection = this.getSelectedSubSections()
        this.setState({
            showReferenceForm: true,
            referencedItem: subSection[0],
            reference: item
        })
    }

    render() {
        const {section} = this.props;
        let content = <p>Select a section</p>
        if(section){
            if(this.loadedSectionId !== section.id){
                this.loadedSectionId = section.id;
                setTimeout(()=>{
                    this.loadedSubSections = [];
                    this.loadSubsections(section.id, {});
                }, 100)
            }
            content = this.renderContentWithSection();
        }
        else{
            setTimeout(()=>{
                this.setState({loadingSubSections: false})
            }, 100)
        }
        const menu = this.buildMenu()
        const title = <div>
            <div className={"section-menu"}>{menu}</div>
        </div>
        let refDialog = "";
        if(this.state.showReferenceForm === true){
            let referenceItem = {};
            if(this.state.reference){
                referenceItem = this.state.reference;
            }
            else{
                referenceItem.identity = "-1";
            }
            refDialog = <LegislationReferenceDialog referencingItem={referenceItem} contentType={ContentType.subSection}
                                                    didCompleteSave={e => this.didCompleteReferenceSave(e)}
                didRequestDismissal={e => this.onDismissReferenceModal()} title="Sub Section Reference"/>
        }
        return <div>
            <Spin tip={"Loading sub sections..."} spinning={this.state.loadingSubSections}>
                <Card title={title} className="sub-section-wrapper-inner" actions={this.getCardActions()}>
                    {content}
                </Card>
            </Spin>
            {refDialog}
        </div>
    }

    onDismissReferenceModal() {
        this.setState({
            showReferenceForm: false,
            referencedItem: null,
            reference: null
        })
    }

    didCompleteReferenceSave(value) {
        const referenceItem = this.state.referencedItem
        if(this.props.onSaveReferenceRequested){
            this.props.onSaveReferenceRequested(referenceItem, value, ContentType.subSection.value, () => {
                this.reloadCurrentSubSections();
                this.setState({
                    showReferenceForm: false,
                    referencedItem: null,
                    reference: null
                });
            })
        }
    }

    getCardActions(){
        const actions = [];
        if(!this.state.showFilters){
            return actions;
        }
        actions.push(
            <Switch
                key="index-switch"
                checked={this.state.showIndexes}
                onChange={e => this.onShowIndexChanged(e)}
                checkedChildren="Hide Indexes"
                unCheckedChildren="Show Indexes"
            />
        )
        actions.push(
            <Switch
                key="select-switch"
                checked={this.state.allowMultiSelect}
                onChange={e => this.onChangeMultiSelect(e)}
                checkedChildren="Multi-Select"
                unCheckedChildren="Multi-Select"
            />
        )
        actions.push(
            <Switch
                key="published-switch"
                checked={this.state.showPublished}
                onChange={e => this.onShowPublishedChanged(e)}
                checkedChildren="Published"
                unCheckedChildren="Published"
            />
        )
        actions.push(
            <Switch
                key="draft-switch"
                checked={this.state.showDraft}
                onChange={e => this.onShowDraftChanged(e)}
                checkedChildren="Draft"
                unCheckedChildren="Draft"
            />
        )
        return actions;
    }
}

const mapStateToProps = ({ contentManagement }) => {
    return {contentManagement};
}

const mapDispatchToProps = {
    showPageLoader, hidePageLoader, showNetworkErrorMessage
}

LegislationSubSection.propTypes = {
    legislation: PropTypes.any,
    section: PropTypes.any,
    onSubSectionMenuItemClicked: PropTypes.func,
    onSaveReferenceRequested: PropTypes.func,
    onDeleteReferenceRequested: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(LegislationSubSection);
