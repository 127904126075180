import React from "react";
import PropTypes from "prop-types";
import {hidePageLoader, showNetworkErrorMessage, showPageLoader} from "../../../../redux/slices/theme";
import {connect} from "react-redux";
import {Form, Input, Modal, Select, Spin} from "antd";
import BackendContentService from "../../../../services/content_services";
import lodash from "lodash";

class LegislationReferenceDialog extends React.Component{
    formRef = React.createRef();
    preloadedDefaultSubsections = false;
    constructor(props) {
        super(props);
        this.state = {
            legislationAndSections: null,
            loadingLegislation: true,
            filteredLegislation: [],
            allLegislation: [],
            selectedLegislation: null,
            loadingSubsections: false,
            subSectionList: null,
            selectedSection: null
        }
        this.loadLegislation();
    }
    onDoneClicked(){
        this.formRef.current.submit();
    }

    submitCallback(values){
        values.sectionNumber = this.state.selectedSection.section_label
        values.subSectionNumber = this.state.subSectionList[values.subSectionId].sub_section_number_string;
        if(!values.legislationTitle) {
            values.legislationTitle = this.state.selectedLegislation.title;
        }
        if(this.props.didCompleteSave) {
            this.props.didCompleteSave({...values});
        }
    }
    onModalDismissed(){
        if(this.props.didRequestDismissal){
            this.props.didRequestDismissal();
        }
    }
    onSearchOrganizations(searchText) {
        const {legislationAndSections} = this.state;
        const search = searchText.toLowerCase();
        const legislationAndSectionsArray = lodash.values(legislationAndSections);
        const filteredLegislation = lodash.filter(legislationAndSectionsArray, (legislation) => {
            return lodash.includes(legislation.acronym.toLowerCase(), search)
                || lodash.includes(legislation.title.toLowerCase(), search)
                || lodash.includes(legislation.synopsis.toLowerCase(), search);
        });
        this.setState({filteredLegislation});
    }
    onLegislationSelected(legislationId){
        let legislation = null;
        if (this.state.legislationAndSections) {
            legislation = this.state.legislationAndSections[legislationId];
            this.setState({
                selectedLegislation: legislation
            });
            setTimeout(()=>{
                if(this.preloadedDefaultSubsections === false){
                    this.preloadedDefaultSubsections = true;
                    if(this.props.referencingItem && this.props.referencingItem.sectionId) {
                        this.onLegislationSectionSelected(this.props.referencingItem.sectionId, false);
                    }
                }
            }, 500);
        }
    }
    onLegislationSectionSelected(sectionId, leaveSubSectionId){
        if(this.state.selectedLegislation){
            const section = this.state.selectedLegislation.sections[sectionId]
            this.setState({
                selectedSection: section,
                subSectionList: null
            });
            if(leaveSubSectionId === false){

            }
            else{
                this.formRef.current.setFieldsValue({subSectionId: null});
            }
            this.loadSubSections(section);
        }
    }
    loadSubSections(section) {
        const selectedSection = section || this.state.selectedSection;
        if(selectedSection){
            let number = selectedSection.section_number;
            const selectLegislationId = this.state.selectedLegislation.id;
            BackendContentService.getLegislationSectionSummary(selectLegislationId, number).then((response) => {
                const result = response.result[0].legislationItems;
                const state = {
                    loadingSubsections: false
                };
                if(result){
                    const resultLegislation = result[selectLegislationId];
                    const section = resultLegislation.sections[selectedSection.id];
                    if(section) {
                        state.subSectionList = section.subSections;
                    }
                }
                this.setState(state);
            }).catch(error => {
                this.setState({
                    loadingSubsections: false,
                    subSectionList: null
                })
            });
        }
    }
    loadLegislation() {
        BackendContentService.getLegislationSummary().then((response) => {
            const result = response.result[0].legislationItems;
            const allLegislation = lodash.values(result);
            this.setState({
                legislationAndSections: result,
                filteredLegislation: allLegislation,
                loadingLegislation: false
            });
            if (this.props.referencingItem && this.props.referencingItem.legislationId){
                const legislation = result[this.props.referencingItem.legislationId];
                this.onLegislationSelected(legislation.id);
            }
        }).catch(error => {
            this.setState({
                loadingLegislation: false
            })
        });
    }
    getSectionField() {
        const {selectedLegislation} = this.state;
        if(selectedLegislation) {
            let sections = selectedLegislation.sections;
            sections = lodash.sortBy(sections, (section) => {return section.section_sort})
            return <Form.Item name="sectionId" label="Section"  rules={[
                {
                    required: true,
                    message: 'Select a section',
                },
            ]}>
                <Select
                    showSearch
                    placeholder="Select Section"
                    onChange={e => this.onLegislationSectionSelected(e)}
                    style={{ width: '100%' }}>
                    {sections.map(d => (
                        <Select.Option key={d.id} value={d.id}> {d.section_label} - {d.title}</Select.Option>
                    ))}
                </Select>
            </Form.Item>
        }
        return "";
    }
    getSubSectionField() {
        const {subSectionList} = this.state;
        if(subSectionList) {
            let subsections = lodash.values(subSectionList);
            subsections = lodash.sortBy(subsections, (subsection) => {return subsection.sub_section_sort})
            return <Form.Item name="subSectionId" label="Sub Section" rules={[
                {
                    required: true,
                    message: 'Select sub section',
                },
            ]}>
                <Select
                    showSearch
                    placeholder="Select Sub Section"
                    style={{ width: '100%' }}>
                    {subsections.map(d => (
                        <Select.Option key={d.id} value={d.id}> {d.sub_section_number_string}</Select.Option>
                    ))}
                </Select>
            </Form.Item>
        }
        return "";
    }
    render() {
        const {title, referencingItem} = this.props;
        const {loadingLegislation, loadingSubsections, filteredLegislation} = this.state;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const formContent = {...referencingItem}
        const sectionField = this.getSectionField();
        const subSectionField = this.getSubSectionField();
        return <Modal
            className="reference-modal"
            visible={true}
            width="60%"
            title={title} closable={true}
            onOk={e => this.onDoneClicked()}
            destroyOnClose={true}
            okText="Save"
            cancelText="Cancel"
            centered={true} onCancel={e => this.onModalDismissed()}>
            <Spin tip={"Loading..."} spinning={loadingSubsections}>
                <Form className="reference-edit-form"
                      ref={this.formRef}
                      {...formItemLayout}
                      name="register"
                      onFinish={e => this.submitCallback(e)}
                      initialValues={formContent}
                      scrollToFirstError>
                    <Form.Item name="name" rules={[
                        {
                            required: true,
                            message: 'Please enter a label',
                        },
                    ]} label="Label">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="legislationId" label="Legislation"  rules={[
                        {
                            required: true,
                            message: 'Select a legislation',
                        },
                    ]}>
                        <Select
                            disabled={loadingLegislation === true}
                            showSearch
                            placeholder="Type to filter"
                            filterOption={true}
                            onSearch={e => this.onSearchOrganizations(e)}
                            loading={loadingLegislation}
                            onChange={e => this.onLegislationSelected(e)}
                            style={{ width: '100%' }}>
                            {filteredLegislation.map(d => (
                                <Select.Option key={d.id} value={d.id}>{d.title}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item hidden={true} name="identity"/>
                    <Form.Item hidden={true} name="legislationTitle"/>
                    <Form.Item hidden={true} name="sectionNumber"/>
                    <Form.Item hidden={true} name="subSectionNumber"/>
                    {sectionField}
                    {subSectionField}
                </Form>
            </Spin>

        </Modal>
    }
}

LegislationReferenceDialog.propTypes = {
    referencingItem: PropTypes.any,
    title: PropTypes.string,
    contentType: PropTypes.any,
    didCompleteSave: PropTypes.func,
    didRequestDismissal: PropTypes.func
}

const mapStateToProps = ({ contentManagement }) => {
    return {contentManagement};
}

const mapDispatchToProps = {
    showPageLoader, hidePageLoader, showNetworkErrorMessage
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(LegislationReferenceDialog);