import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Button, Modal} from "antd";
const { confirm } = Modal;

class LegislationReferenceList extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            showDeleteConfirm: false
        }
    }

    requestedEdit(item){
        if(this.props.didRequestEdit){
            this.props.didRequestEdit(item);
        }
    }

    requestedDelete(item){
        confirm({
            title: 'Remove Reference?',
            content: `Are you sure you want to remove the reference - ${item.name}`,
            onOk: () => {
                if(this.props.didRequestReferenceDelete){
                    this.props.didRequestReferenceDelete(item);
                }
            },
            onCancel() {},
        });
    }

    render() {
        const  {references} = this.props;
        const referenceList = references.map(item => {
            return <div className="reference-item" key={item.identity}>
                <div className="info">
                    <div className="name">{item.name}</div>
                    <div className="legislation">{item.legislationTitle} <span>Section {item.sectionNumber} ({item.subSectionNumber})</span></div>
                </div>
                <div className="actions">
                    <Button onClick={e => this.requestedEdit(item)}>Edit</Button> <Button type={"link"} onClick={e => this.requestedDelete(item)}>Delete</Button>
                </div>
            </div>
        })
        return <div className="reference-list">
            {referenceList}
        </div>
    }
}

LegislationReferenceList.propTypes = {
    references: PropTypes.array,
    didRequestEdit: PropTypes.func,
    didRequestReferenceDelete: PropTypes.func
}

const mapStateToProps = ({ contentManagement }) => {
    return {contentManagement};
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(LegislationReferenceList);