import DefaultConfigurator, {ContentDetailsConfigurator} from "./base";
import React from "react";
import {Typography} from "antd";

const { Text, Title } = Typography;

export default class CircularConfigurator extends DefaultConfigurator{
    updateColumns(columns){
        const regulatory_agency = {
            title: 'Regulatory Agency',
            dataIndex: 'regulatory_agency',
            key: 'regulatory_agency',
        }
        columns.splice(1, 0, regulatory_agency);
        return columns;
    }
    createDetailsConfigurator(component){
        return new DetailsConfigurator(component);
    }
}

class DetailsConfigurator extends ContentDetailsConfigurator{
    getPostTitleComponent(content) {
        return <Text>{content.regulatory_agency}</Text>
    }
}